<template>
  <div class="max-w-3xl mx-auto">
    <h1 v-if="!appVersion.allina">{{ $t("weeklyRecur.title") }}</h1>
    <h1 v-if="appVersion.allina">Do you have any dietary restrictions?</h1>
    <p class="mt-2 text-gray-800" v-if="!appVersion.allina">{{ $t("weeklyRecur.description") }}</p>
    <v-form v-model="valid" class="mt-6 mb-2">
      <v-radio-group :rules="rules.required" v-if="!appVersion.allina" v-model="form.type">
        <v-radio :label="$t('weeklyRecur.form.type.choice')" value="Recurring, same meals"></v-radio>
        <v-radio :label="$t('weeklyRecur.form.type.dietary')" value="Recurring, chef's choice"></v-radio>
        <v-radio :label="$t('weeklyRecur.form.type.none')" value="One-time"></v-radio>
      </v-radio-group>

      <p class="mt-4">
        {{ $t("weeklyRecur.form.dietaryNote") }}
      </p>
      <v-select
        class="mt-4"
        :items="[
          'Vegetarian',
          'Gluten Free',
          'Lactose Free',
          'Renal',
          'Diabetic Friendly',
          'Low Sodium',
          'Mechanical Soft',
          'Pureed',
          'Halal',
          'Kosher',
          'Traditional Hmong',
          'Latino-focused (Mexican/Latin American flavors)',
          'Other',
          'None'
        ]"
        v-model="form.dietaryPreferences"
        :rules="rules.required"
        multiple
        :label="$t('weeklyRecur.form.dietaryPreferences')"
        outlined
      ></v-select>

      <v-text-field
        v-if="form.dietaryPreferences == 'Other'"
        label="Other Dietary Preference"
        v-model.trim="form.otherDietaryPreference"
        outlined
      ></v-text-field>

      <p v-if="form.type === 'choice'" class="text-red-error font-bold mt-4">
        {{ $t("weeklyRecur.renewalNotice") }}
      </p>
    </v-form>

    <v-footer color="primary lighten-1" app padless>
      <StepNavigation :nextDisabled="!valid" v-on:next="next()" v-on:previous="$emit('previous')" />
    </v-footer>

  </div>
</template>

<script>
export default {
  name: "WeeklyRecurOptions",
  components: {},
  data: () => ({
    valid: false,
    form: {
      type: "",
      dietaryPreferences: [],
      otherDietaryPreference: ""
    },
    rules: {
      required: [v => !!v || "This field is required"]
    },
    appVersion: {
      allina: false
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    if(params.get("app_version") && params.get("app_version") == "allina") {
      this.appVersion.allina = true;
    } else {
      this.appVersion.allina = false;
    }
  },
  methods: {
    next: function() {
      this.$store.commit("setWeeklyRecurringOptions", this.form);
      this.$emit("next");
    }
  }
};
</script>

<style></style>
