<template>
  <v-dialog v-model="dialog" width="90%" max-width="40rem" scrollable content-class="mt-40 modal-dialog-margin">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="type === 'button'" class="mt-8" color="secondary" v-bind="attrs" v-on="on">
        <slot></slot>
      </v-btn>
      <a v-else href="#" class="" v-bind="attrs" v-on="on">
        <slot></slot>
      </a>
    </template>

    <v-card class="pb-4">
      <v-toolbar
          flat
          dense
      >
        <div class="flex-grow-1"></div>
        <v-btn
            icon
            small
            @click="dialog = false"
            color="text-gray-400"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        {{ $t("paymentHelp.title") }}
      </v-card-title>
      <v-card-text>
        <p class="custom-messages-font-size">
          {{ $t("paymentHelp.body") }}
          <PaymentAssistanceRequest type="link">{{ $t("paymentHelp.link") }}</PaymentAssistanceRequest>
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PaymentAssistanceRequest from "./PaymentAssistanceRequest";

// eslint-disable-next-line no-unused-vars
export default {
  name: "PaymentOptionHelp",
  components: { PaymentAssistanceRequest },
  props: {
    type: {
      type: String,
      default: "button"
    }
  },
  data: () => ({
    dialog: false
  })
};
</script>
