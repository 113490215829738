<template>
  <div class="max-w-9xl mx-auto">
    <div v-if="$store.getters.isLoading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <div class="grid grid-cols-4 gap-4">
      <div class="col-span-4 xl:col-span-3">
        <div class="rounded-md p-2 mb-4 sm:p-0 sm:mb-2" v-if="!appVersion.allina">
          <p class="text-md mb-2">
            {{ $t("weeklyOrder.paymentAssistance.body", { price: getMealPrice }) }} <span class="text-red-error">{{ $t("interface.mealLimit", { number: 7 }) }}</span><br /><br />
            {{ $t("weeklyOrder.paymentAssistance.body3", { price: getMealPrice }) }} <PaymentAssistanceRequest type="link">{{ $t("weeklyOrder.paymentAssistance.link") }}</PaymentAssistanceRequest>
            {{ $t("weeklyOrder.paymentAssistance.body2") }}
          </p>
          <p class="text-md mb-2" v-if="appVersion.allina">
            {{ $t("weeklyOrder.paymentAssistance.body3", { price: getMealPrice }) }}
            {{ $t("weeklyOrder.paymentAssistance.body2") }}
          </p>
          <p class="text-md mb-2">
            <PaymentOptionHelp v-if="!appVersion.allina" type="link">
              {{ $t("paymentHelp.button") }}
            </PaymentOptionHelp>
          </p>
        </div>
        <p class="text-md mb-2">
          {{ $t("weeklyOrder.dietaryInformation") }}
        </p>
        <p class="text-md mb-4 text-red-error">

        </p>
        <div v-for="(menu, index) in menus" :key="menu.id">
          <div class="flex justify-between items-end flex-wrap">
            <div class="w-full md:w-auto">
              <h1 class="mb-2">{{ menu.name }}</h1>
              <p v-if="index === 0" class="text-sm mb-2">
                <span v-if="!appVersion.allina">{{ $t("weeklyOrder.currentMenu") }}</span>
                <span v-if="appVersion.allina">Order up to 14 meals. A chef-selected variety of meals will be delivered.</span>
              </p>
              <p v-else class="text-sm mb-2">
                {{
                  $t("weeklyOrder.availableDates", {
                    start: getReadableStringFromDate(menu.starts),
                    end: getReadableStringFromDate(menu.ends)
                  })
                }}
              </p>
              <p class="text-sm mb-2" v-if="!appVersion.allina">
                {{ $t("weeklyOrder.imageDisclaimer") }}
              </p>
            </div>
            <div class="max-w-xs w-full md:w-auto">
              <v-select
                :items="availableFilters"
                v-model="filters"
                dense
                multiple
                :label="$t('interface.filter')"
                outlined
              ></v-select>
            </div>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
            <div v-for="meal in filteredMeals(menu.meals)" :key="meal.id" class="">
              <div class="bg-white shadow-md border border-gray-300 border-solid rounded">
                <h2 class="px-4 py-4">{{ meal.name }}</h2>
                <img :src="meal.image" class="w-full h-auto" alt="" />
                <div class="px-4 py-2">
                  <div v-html="meal.description"></div>
                  <AddForm :menu="menu" :meal="meal" v-on:addmeal="addMeal($event)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-4 md:col-span-2 xl:col-span-1 relative mt-4">
        <div
          v-show="!$store.getters.isLoading"
          class="xl:fixed max-w-sm bg-white shadow-md border border-gray-300 border-solid rounded p-4 max-h-half overflow-y-auto custom-cart-box-styles custom-z-index-override"
          :class="{'mt-38': !$store.getters.isWeeklyOrderValid}"
        >
          <h1>{{ $t("interface.ordered") }}</h1>
          <WeeklyOrderedItems />
          <v-btn
            class="w-full mt-4"
            :disabled="!$store.getters.isWeeklyOrderValid"
            v-on:click="$emit('scrollTop');$emit('next')"
            color="primary"
            >{{ $t("interface.continue") }}</v-btn
          >
        </div>
      </div>
    </div>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation
        :nextDisabled="!$store.getters.isWeeklyOrderValid"
        v-on:next="$emit('next')"
        v-on:previous="$emit('previous')"
      />
    </v-footer>
    <v-snackbar v-model="snackbar.display" :timeout="3000">
      {{ snackbar.message }}
      <v-btn color="pink" text @click="snackbar.display = false">
        {{ $t("interface.close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import AddForm from "./components/AddForm";
import { format, addMinutes, subMinutes } from "date-fns";
import PaymentOptionHelp from "./../support/PaymentOptionHelp";
import WeeklyOrderedItems from "./components/WeeklyOrderedItems";
import PaymentAssistanceRequest from "./../support/PaymentAssistanceRequest";

export default {
  name: "WeeklyOrder",
  components: {
    AddForm,
    PaymentOptionHelp,
    WeeklyOrderedItems,
    PaymentAssistanceRequest
  },
  data: () => {
    return {
      filters: [],
      snackbar: {
        display: false,
        message: ""
      },
      appVersion: {
        allina: false
      },

    };
  },
  computed: {
    menus: function() {
      if(this.appVersion.allina === true) {
        let allinaMenu = this.$store.getters.matchingMenus[0];
        allinaMenu.name = "Order Frozen Meals";
        let meal = allinaMenu.meals[0];
        meal.name = "Frozen meal - Chef's Choice";
        meal.sku = "FROZENMEALCHEFSCHOICE";
        meal.description = "";
        allinaMenu.meals = [meal];
        return [allinaMenu];
      } else {
        return this.$store.getters.matchingMenus;
      }
    },
    availableFilters: function() {
      return this.$store.getters.mealCategories;
    },
    getMealPrice: function() {
        let mealPrice = this.$store.getters.getPerMealPrice;
        let mealPriceHalal = this.$store.getters.getPerMealPriceHalal;
        const hasMeals = this.$store.getters.getNumberOfMeals > 0
        const hasMealsHalal = this.$store.getters.getNumberOfMealsHalal > 0;
        if (!hasMeals && hasMealsHalal) {
            return mealPriceHalal;
        } else if (hasMealsHalal && mealPrice !== mealPriceHalal) {
            if ( parseFloat(mealPrice).toFixed(2) < parseFloat(mealPriceHalal).toFixed(2)) {
                return`${mealPrice} - ${mealPriceHalal}`
            } else {
                return`${mealPriceHalal} - ${mealPrice}`
            }
        }
        return mealPrice;
    }
  },
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  methods: {
    getReadableStringFromDate: function(isoString) {
      // isoString we get has no timezone. When it gets created, the internal date is considered
      // midnight UTC. When we display the date in the browser, the timezone gets factored in,
      // and you can roll over into the previous day.
      let date = new Date(isoString);
      // This will get the number of minutes we are currently off from UTC.
      let adjustment = date.getTimezoneOffset();
      // We need to add those minutes to the UTC date so that when we display the date, it
      // will show up correctly.
      if (adjustment > 0) {
        date = addMinutes(date, adjustment);
      } else {
        date = subMinutes(date, Math.abs(adjustment));
      }
      return format(new Date(isoString), "MMMM do");
    },
    addMeal($event) {
      this.snackbar.display = true;
      this.snackbar.message = "Meal Added";
      this.$store.commit("addMeal", $event);
    },
    filteredMeals(meals) {

      return meals.filter(meal => {
        return this.filters.every(cat =>
          meal.categories
            .map(category => {
              return category.name;
            })
            .includes(cat)
        );
      });
    }
  }
};
</script>

<style></style>
