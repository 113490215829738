<template>
  <div class="max-w-xl mx-auto">
    <h1>{{ $t("referrer.title") }}</h1>

    <v-form id="referrer" v-model="valid" class="mt-6 mb-2">
      <v-select
          :items="$t('referrer.form.choices.choices')"
          v-model="choices"
          multiple
          :label="$t('referrer.form.choices.label')"
          outlined
      ></v-select>

      <v-text-field
          v-if="choices.includes('Other')"
          :label="$t('referrer.form.otherChoice.label')"
          v-model.trim="otherChoice"
          :rules="rules.required"
          outlined
      ></v-text-field>
    </v-form>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation :nextDisabled="!valid" v-on:next="next()" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Referrer",
  data: () => {
    return {
      valid: false,
      choices: [],
      otherChoice: "",
      rules: {
        required: [(v) => !!v || "This field is required"],
      },
    };
  },
  methods: {
    next: function () {
      this.$store.commit("setReferrer", { choices: this.choices, other: this.otherChoice });
      if (this.$store.getters.isCreditCardPayment) {
        this.$store.commit("setStripeLoading", true);
        this.$store.commit("setLoading", true);
        // Set up Stripe payment prior to going into payment step
        this.$store.commit("createStripeClient");
        this.$store.commit("createStripeElements");
        this.$store.dispatch("createStripeClientSecret").then(() => {
          this.$store.commit("createStripeDomElements");
          this.$store.commit("setStripeLoading", false);
        });
      }
      this.$store.commit("setLoading", false);
      this.$emit("scrollTop");
      this.$emit("next");
    },
  },
}
</script>

<style scoped>

</style>
