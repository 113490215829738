<template>
  <div class="max-w-2xl mx-auto">
    <div v-if="$store.getters.isPrivatePaymentType">
      <h1>{{ $t("submitter.private.title") }}</h1>

      <v-radio-group v-model="orderingFor">
        <v-radio :label="$t('submitter.private.form.for.myself')" value="myself"></v-radio>
        <v-radio :label="$t('submitter.private.form.for.other')" value="other"></v-radio>
      </v-radio-group>

      <div class="mt-2" v-if="orderingFor === 'other'">
        <h1>{{ $t("submitter.private.relationship") }}</h1>

        <v-form v-model="otherFormValid">

          <v-text-field
            v-model.trim="filler.name"
            :label="$t('submitter.private.form.submitter.name')"
            :rules="rules.required"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="filler.phone"
            v-mask="'###-###-####'"
            :label="$t('submitter.private.form.submitter.phone')"
            :rules="rules.phone"
            outlined
          ></v-text-field>

          <v-text-field
            v-model.trim="filler.email"
            :label="$t('submitter.private.form.submitter.email')"
            :rules="rules.email"
            outlined
          ></v-text-field>

          <v-text-field
            v-model.trim="filler.relationshipToRecipient"
            :label="$t('submitter.private.form.relationship.personal')"
            outlined></v-text-field>

        </v-form>
      </div>
    </div>

    <div v-if="$store.getters.isWaiverPaymentType">
      <h1 class="mb-4" v-if="!appVersion.allina">{{ $t("submitter.waiver.title") }}</h1>
      <h1 class="mb-4" v-if="appVersion.allina">{{ $t("submitter.waiver.title_allina") }}</h1>

      <v-form v-model="referrerFormValid">
        <v-text-field
          v-model.trim="filler.name"
          :label="$t('submitter.waiver.form.name')"
          :rules="rules.required"
          outlined
        ></v-text-field>

        <v-text-field
          v-model="filler.phone"
          v-mask="'###-###-####'"
          :label="$t('submitter.waiver.form.phone')"
          :rules="rules.phone"
          outlined
        ></v-text-field>

        <v-text-field
          v-model="filler.email"
          :label="$t('submitter.waiver.form.email')"
          :rules="(!appVersion.allina) ? rules.required.concat(rules.email) : []"
          outlined
        ></v-text-field>

        <h1 class="mb-4" v-if="!appVersion.allina">{{ $t("submitter.waiver.agencyInformation") }}</h1>

        <v-text-field
          v-model.trim="filler.agency.name"
          :label="$t('submitter.waiver.form.agency.name')"
          :rules="rules.required"
          outlined
           v-if="!appVersion.allina"
        ></v-text-field>

        <v-text-field
          v-model.trim="filler.agency.address"
          :label="$t('submitter.waiver.form.agency.address')"
          :rules="rules.required"
          outlined
          v-if="!appVersion.allina"
        ></v-text-field>
      </v-form>
    </div>
    <v-footer color="primary lighten-1" app padless>
     <StepNavigation :nextDisabled="!valid" v-on:next="next" v-on:previous="previous" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Submitter",
  components: {},
  data: () => ({
    otherFormValid: false,
    referrerFormValid: false,
    orderingFor: "",
    filler: {
      relationshipToRecipient: "",
      name: "",
      phone: "",
      email: "",
      agency: {
        name: "",
        address: ""
      }
    },
    rules: {
      required: [v => !!v || "This field is required"],
      phone: [
        v => {
          return (
            v.search(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/) > -1 || "Please enter a valid phone number"
          );
        }
      ],
      email: [
        v => {
          return (
            v.search(
              // eslint-disable-next-line no-useless-escape
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) > -1 ||
            "Please enter a valid email"
          );
        }
      ]
    },
    appVersion: {
      allina: false
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  computed: {
    valid: function() {
      if (this.orderingFor === "myself") {
        return true;
      }
      if (this.isAgency && this.referrerFormValid) {
        return true;
      }
      return this.otherFormValid;

    },
    isAgency: function() {
      return this.$store.getters.isWaiverPaymentType;
    }
  },
  methods: {
    next: function() {
      if (this.$store.getters.isWaiverPaymentType) {
        this.$store.commit("setFiller", this.filler);
      } else {
        this.$store.commit("setRecipient", this.orderingFor);
        if (this.orderingFor === "other") {
          this.$store.commit("setFiller", this.filler);
        } else {
          this.$store.commit("clearFiller");
        }
      }
      this.$emit("scrollTop");
      this.$emit("next");
      if(this.appVersion.allina) {
        this.$store.commit("setWaiverInformation", {
          payer: "",
          waiver: "",
          pmi: "",
          payerId: "",
          informationAboutAdditionalCovidRelief: "",
          spenddownOrWaiverObligation: "",
          spenddownOrWaiverObligationAmount: "",
          serviceAgreementAuthorizationRequested: "",
          numberOfMealsAuthorized: "",
          comments: ""
        });
        this.$emit("scrollTop");
        this.$emit("next");
      }
    },
    previous: function() {
      this.$emit('previous');
      if(this.appVersion.allina) {
        this.$emit('previous');
      }
    }
  }
};
</script>

<style></style>
