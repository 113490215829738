<template>
  <div class="pt-2">
    <v-container v-if="schedules.length > 0">
      <v-row dense>
        <v-col
            v-for="schedule in schedules"
            :key="schedule.id"
            cols="12"
        >
          <v-card>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                    class="text-h6"
                    v-text="schedule.meal.name"
                ></v-card-title>

                <v-card-text class="body-2" v-html="schedule.meal.description"></v-card-text>

                <v-card-subtitle class="caption">{{ getReadableDate(schedule.day) }}</v-card-subtitle>
              </div>

              <v-avatar
                  class="ma-3"
                  size="125"
                  tile
                  v-if="schedule.meal.image"
              >
                <v-img :src="schedule.meal.image"></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title
              class="text-h5"
          >Sorry!</v-card-title>

          <v-card-subtitle>There are no meals to preview at this time.</v-card-subtitle>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { sortBy } from "lodash";
import { format } from "date-fns";
import { adjustUtcForCurrentTimezone } from "@/lib/date";

export default {
  name: "UpcomingDailyMeals",
  components: {},
  data: () => ({}),
  methods: {
    getReadableDate: function(string) {
      return format(adjustUtcForCurrentTimezone(new Date(string)), "iiii, MMMM do");
    }
  },
  computed: {
    schedules: function() {
      return sortBy(this.$store.state.upcomingHotMeals, schedule => {
        return schedule.day;
      });
    }
  }
};
</script>

<style></style>
