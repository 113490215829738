import { addMinutes, subMinutes } from "date-fns";

// When you create a date in JS from a string, if no timezone (or time)
// is specified, the date is created as a UTC date at 00:00:000
//
// This becomes a problem when you display the date. In the central timezone
// the time gets adjusted five hours back to account for the timezone. 
//
// This would normally be what we'd want if we had a date that was created
// in another timezone and we needed it to be accurately represented in our
// system. However, this is not the case for this tool. All dates and times
// are assumed to be central for simplicity. 
//
// This helper function takes a date object and adjusts the time in the
// opposite direction as the current timezone. So for a central time,
// the UTC date has an additional 5 hours added to account for the
// difference when the dat is eventually displayed.
export const adjustUtcForCurrentTimezone = function(date){
      // This will get the number of minutes we are currently off from UTC.
      let adjustment = date.getTimezoneOffset();
      // We need to add those minutes to the UTC date so that when we display the date, it
      // will show up correctly.
      if (adjustment > 0) {
        date = addMinutes(date, adjustment)
      } else {
        date = subMinutes(date, Math.abs(adjustment));
      }
      return date;
}