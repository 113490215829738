<template>
  <div class="max-w-4xl mx-auto">
    <h1>
      {{ $t(`emergencyContacts.${$store.getters.subject}.title`) }}
    </h1>
    <v-form v-model="valid" class="mt-6 mb-8">
      <div v-for="(emergencyContact, index) in emergencyContacts" :key="index">
        <div class="flex -mx-2 items-center pt-2 pb-2 w-full">
          <div class="px-2 w-10">
            <p class="whitespace-no-wrap pb-4"># {{ index + 1 }}</p>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-3">
            <v-text-field
              class="px-2"
              v-model.trim="emergencyContact.name"
              :rules="rules.name"
              label="Name"
              required
              outlined
            ></v-text-field>
            <v-text-field
              class="px-2"
              v-model="emergencyContact.phone"
              :rules="rules.phone"
              v-mask="'###-###-####'"
              label="Phone"
              required
              outlined
            ></v-text-field>
            <v-text-field
              class="px-2"
              v-model.trim="emergencyContact.relationship"
              :rules="rules.relationship"
              label="Relationship"
              required
              outlined
            ></v-text-field>
          </div>
          <div class="px-2">
            <v-btn fab dark small class="mb-8" @click="remove(index)">
              <svg fill="currentColor" class="w-6 h-6" viewBox="0 0 20 20">
                <path
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </v-btn>
          </div>
        </div>
        <hr class="mb-6">
      </div>
      <v-btn @click="add" color="primary" class="bg-white ml-10" outlined>{{ $t("emergencyContacts.add") }}</v-btn>
    </v-form>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :nextDisabled="!isValid" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Emergency",
  components: {},
  data: () => ({
    valid: false,
    emergencyContacts: [
      {
        name: "",
        phone: "",
        relationship: ""
      }
    ],
    rules: {
      name: [v => !!v || "A name is required"],
      phone: [v => !!v || "A phone number is required"],
      relationship: [v => !!v || "A relationship status is required"]
    },
    appVersion: {
      allina: false
    }
  }),
  mounted: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  methods: {
    add: function() {
      this.emergencyContacts.push({
        name: "",
        phone: "",
        relationship: ""
      });
    },
    remove: function(index) {
      this.emergencyContacts.splice(index, 1);
    },
    isValid: function() {
      if (this.$store.isWaiverPaymentType){
        return true;
      }
      return this.valid;
    },
    next: function() {
      this.$store.commit("setEmergencyContacts", this.emergencyContacts);
      this.$emit("scrollTop");
      this.$emit("next");
      if(this.appVersion.allina) {
        this.$emit("scrollTop");
        this.$emit("next");
      }
    }
  }
};
</script>

<style></style>
