<template>
  <div class="max-w-2xl mx-auto">
    <h1>{{ $t(`household.${$store.getters.subject}.title`) }}</h1>
    <v-form v-model="valid" class="mt-6 mb-2">
      <v-radio-group v-model="householdInformation.status" :mandatory="true">
        <v-radio :label="$t(`household.${$store.getters.subject}.livesAloneOption`)" value="single"></v-radio>
        <v-radio :label="$t(`household.${$store.getters.subject}.livesWithOthersOption`)" value="multiple"></v-radio>
      </v-radio-group>
      <v-text-field
        v-if="householdInformation.status === 'multiple'"
        :label="$t('household.form.numberInHousehold')"
        v-model="householdInformation.numberOfPeople"
        type="number"
        required
        outlined
      ></v-text-field>
      <v-text-field
        v-if="$store.getters.isPrivatePaymentType"
        :label="$t('household.form.householdMonthlyIncome')"
        v-model="householdInformation.income"
        prefix="$"
        outlined
      ></v-text-field>
    </v-form>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :nextDisabled="!valid" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Contact",
  components: {},
  data: () => ({
    valid: false,
    householdInformation: {
      status: "",
      numberOfPeople: "",
      income: ""
    },
    rules: {
      contactInformation: {
        email: [v => !!v || "An email is required"]
      }
    }
  }),
  methods: {
    next: function() {
      this.$store.commit("setHouseholdInformation", this.householdInformation);
      this.$emit("scrollTop");
      this.$emit("next");
    }
  }
};
</script>

<style></style>
