/*global Stripe*/

import Vue from "vue";
import Vuex from "vuex";
import fetch from "unfetch";
import { find, every, get, remove, cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { eachDayOfInterval, parse, format, addBusinessDays, isWithinInterval, max } from "date-fns";
import { adjustUtcForCurrentTimezone } from "./lib/date";

Vue.use(Vuex);

/**
 * @typedef SingleOrder
 * @type {object}
 * @property {object.<number, SingleOrderGroup>} groups
 *
 * @typedef SingleOrderGroup
 * @type {object}
 * @property
 *
 */

// const weeklyLeadDays = 6;
let weeklyLeadDays = 8 // Change from 6 to 8?
let uri = window.location.search
let params = new URLSearchParams(uri)
if(params.get("app_version") && params.get("app_version").toLowerCase() === "allina") {
  weeklyLeadDays = 4
}
const dailyLeadDays = 5;
const dailyMaxLeadDays = 30; // People, at most, can schedule daily delivery 30 days in advance

export const store = new Vuex.Store({
  state: {
    step: 1,
    loading: false,
    districts: [],
    frozenMenus: [],
    upcomingHotMeals: [],
    businessDays: [],
    firstAvailableDayDailyDelivery: null,
    firstAvailableDayWeeklyDelivery: null,
    id: null,
    entry: {
      addresses: {
        mailing: {},
        billing: {}
      },
      deliveryInstructions: "",
      hasBillingAddress: false,
      district: {},
      type: "",
      orderingFor: "",
      filler: {
        relationshipToRecipient: "",
        name: "",
        phone: "",
        agency: {
          name: "",
          address: ""
        }
      },
      order: {
        weekly: {
          groups: [],
          drink: "",
          recurringOptions: {
            type: "",
            dietaryPreferences: []
          }
        },
        daily: {
          mealsPerDay: "",
          daysOfWeekToReceive: {},
          dietaryPreferences: [],
          delivery: {
            date: null
          }
        }
      },
      waiverInformation: {},
      paymentType: "",
      paymentMethod: "",
      paymentIntentId: "",
      personalInformation: {},
      contactInformation: {},
      householdInformation: {},
      contacts: {
        emergency: [],
        doctors: []
      },
      referrer: []
    },
    stripe: {
      loading: false,
      client: null,
      elements: null,
      clientSecret: null,
      card: null,
      // Use cardState object to track changes to card form, rather than using direct DOM manipulation
      cardState: {
        disabled: true,
        error: ""
      }
    },
    servTracker: {
      expires:0,
      access_token: ''
    },
  },
  getters: {
    isLoading: state => {
      return state.loading;
    },
    isStripeLoading: state => {
      return state.stripe.loading;
    },
    isDailyOrder: state => {
      return state.entry.type === "daily";
    },
    isWeeklyOrder: state => {
      return state.entry.type === "weekly";
    },
    isOrderingForThemselves: state => {
      return state.entry.orderingFor === "myself";
    },
    orderGroups: state => {
      return state.entry.order.weekly.groups;
    },
    isPaymentSet: state => {
      return state.entry.paymentMethod !== "";
    },
    isCreditCardPayment: state => {
      return state.entry.paymentMethod === "creditCard";
    },
    isPrivatePaymentType: state => {
      return state.entry.paymentType === "private";
    },
    isWaiverPaymentType: state => {
      return state.entry.paymentType === "waiver";
    },
    isOrderRepeating: (state, getters) => {
      if (getters.isDailyOrder) {
        return true;
      }
      return state.entry.order.weekly.recurringOptions.type !== "none";

    },
    mealCategories: state => {
      if (state.frozenMenus) {
        return [
          ...new Set(
            state.frozenMenus
              .flatMap(menu => {
                return menu.meals;
              })
              .flatMap(meal => {
                return meal.categories;
              })
              .flatMap(category => {
                return category.name;
              })
          )
        ].filter(category => category);
      }
    },
    getOrderedMeal: state => (menu, meal) => {
      return state.entry.order.weekly.groups
        .filter(group => {
          return group.menu.id === menu.id;
        })
        .flatMap(group => {
          return group.meals;
        })
        .filter(addedMeal => {
          return addedMeal.meal.id === meal.id;
        });
    },
    isDeliveryValid: state => {
      // This method computes whether the delivery values are set for
      // the subscription order or for all single order groups
      if (state.entry.type === "daily") {
        if (get(state, "entry.order.daily.delivery.date")) {
          return true;
        }
      }

      if (state.entry.type === "weekly") {
        return every(state.entry.order.weekly.groups, function(group) {
          return !!group.delivery.date;
        });
      }

      // default false
      return false;
    },
    isWeeklyOrderValid: state => {
      // Check if there is at least one meal selected for the weekly order
      return find(state.entry.order.weekly.groups, group => {
        return group.meals.length > 0;
      });
    },
    getNumberOfMeals: state => {
      if (state.entry.type === "daily") {
        return state.entry.order.daily.mealsPerDay * state.entry.order.daily.daysOfWeekToReceive.length;
      }

      if (state.entry.type === "weekly" && state.entry.order.weekly.groups.length > 0) {
        return state.entry.order.weekly.groups.reduce((carry, group) => {
          return (
              carry +
              group.meals.reduce((carry, meal) => {
                return carry + meal.quantity;
              }, 0)
          );
        }, 0);
      }
      return 0;
    },
    getNumberOfMealsHalal: state => {
      if (state.entry.type === "weekly" && state.entry.order.weekly.groups.length > 0) {
        const groups = state.entry.order.weekly.groups.map( group => {
          return {...group, meals: group.meals.filter( meal => meal.meal.categories.find( cat => cat.value === 'halal' ))}
        });

        return groups.reduce((carry, group) => {
          return (
              carry +
              group.meals.reduce((carry, meal) => {
                return carry + meal.quantity;
              }, 0)
          );
        }, 0);
      }
      return 0;
    },
    getPerMealPrice: state => {
      return state.entry.district.price_per_meal ?? 6.5;
    },
    getPerMealPriceHalal: (state, getters) => {
      return state.entry.district.price_per_meal_halal ?? getters.getPerMealPrice;
    },
    getPurchasePrice: (state, getters) => {
      let price = (getters.getPerMealPrice * getters.getNumberOfMeals) + (getters.getPerMealPriceHalal * getters.getNumberOfMealsHalal);
      return price.toFixed(2) ?? 0;
    },
    matchingMenus(state) {
      let ids = state.entry.district.menus.map(menu => menu.id);
      return state.frozenMenus.filter(menu => {
        const start = adjustUtcForCurrentTimezone(new Date(menu.starts));
        const end = adjustUtcForCurrentTimezone(new Date(menu.ends));
        return ids.includes(menu.id) && isWithinInterval(new Date(), { start: start, end: end });
      });
    },
    isDateValidForWeeklyDelivery: (state, getters) => (menu, date) => {
      return getters.getAvailableDatesForWeeklyOrder(menu).includes(date);
    },
    getAvailableDatesForWeeklyOrder: state => menu => {
      /*const start = closestTo(new Date(), [
        adjustUtcForCurrentTimezone(new Date(menu.starts)),
        state.firstAvailableDayWeeklyDelivery
      ]);*/
      const start = max([
        adjustUtcForCurrentTimezone(new Date(menu.starts)),
        state.firstAvailableDayWeeklyDelivery,
      ])

      const end = adjustUtcForCurrentTimezone(new Date(menu.ends));
      if (start && end) {
        const interval = eachDayOfInterval({ start: start, end: end });

        return interval
          .map(day => {
            return format(day, "yyyy-MM-dd");
          })
          .filter(day => {
            return !state.businessDays.includes(day);
          });
      }
      return [];
    },
    isDateValidForDailyDelivery: (state, getters) => date => {
      return getters.getAvailableDatesForDailyOrder.includes(date);
    },
    getAvailableDatesForDailyOrder: state => {
      const start = state.firstAvailableDayDailyDelivery;
      const end = addBusinessDays(state.firstAvailableDayDailyDelivery, dailyMaxLeadDays);

      if (start && end) {
        const interval = eachDayOfInterval({ start: start, end: end });

        return interval
          .map(day => {
            return format(day, "yyyy-MM-dd");
          })
          .filter(day => {
            return !state.businessDays.includes(day);
          });
      }
      return [];
    },
    subject: (state, getters) => {
      return getters.isOrderingForThemselves ? "forSelf" : "forOther";
    },
    acceptsCreditCard: state => {
      return state.entry.district.accept_credit_card;
    },
  },
  mutations: {
    setStep(state, step) {
      state.step = step;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setStripeLoading(state, isLoading) {
      state.stripe.loading = isLoading;
    },
    generateSubmissionId(state) {
      state.id = uuidv4();
    },
    // Mailing Address
    setMailingAddress(state, address) {
      state.entry.addresses.mailing = address;
    },
    clearMailingAddress(state) {
      state.entry.addresses.mailing = {};
    },
    // Billing Address
    setBillingAddress(state, address) {
      state.entry.hasBillingAddress = true;
      state.entry.addresses.billing = address;
      state.entry.deliveryInstructions = address.deliveryInstructions;
    },
    // Delivery Instructions
    setDeliveryInstructions(state, deliveryInstructions) {
      state.entry.deliveryInstructions = deliveryInstructions;
    },
    clearBillingAddress(state) {
      state.entry.hasBillingAddress = false;
      state.entry.addresses.billing = {};
    },
    // Order Type
    setType(state, type) {
      state.entry.type = type;
    },
    clearType(state) {
      state.entry.type = "";
    },
    // Recipient
    setRecipient(state, recipient) {
      state.entry.orderingFor = recipient;
    },
    clearRecipient(state) {
      state.entry.orderingFor = "";
    },
    // Filler
    setFiller(state, filler) {
      state.entry.filler = filler;
    },
    clearFiller(state) {
      state.entry.filler = {
        relationshipToRecipient: "",
        name: "",
        phone: ""
      };
    },
    // Waiver
    setWaiverInformation(state, waiverInformation) {
      state.entry.waiverInformation = waiverInformation;
    },
    // Payment Type - private vs waiver
    setPaymentType(state, paymentType) {
      state.entry.paymentType = paymentType;
    },
    // Payment Method - cc, insurance, etc
    setPaymentMethod(state, paymentMethod) {
      state.entry.paymentMethod = paymentMethod;
    },
    clearPaymentMethod(state) {
      state.entry.paymentMethod = "";
    },
    // Personal Information
    setPersonalInformation(state, personalInformation) {
      state.entry.personalInformation = personalInformation;
    },
    clearPersonalInformation(state) {
      state.entry.personalInformation = {};
    },
    // Contact Information
    setContactInformation(state, contactInformation) {
      state.entry.contactInformation = contactInformation;
    },
    clearContactInformation(state) {
      state.entry.contactInformation = {};
    },
    // Household Information
    setHouseholdInformation(state, householdInformation) {
      state.entry.householdInformation = householdInformation;
    },
    clearHouseholdInformation(state) {
      state.entry.householdInformation = {};
    },
    // Emergency Contact
    setEmergencyContacts(state, emergencyContacts) {
      state.entry.contacts.emergency = emergencyContacts;
    },
    clearEmergencyContacts(state) {
      state.entry.contacts.emergency = [];
    },
    // Doctors Contact
    setDoctors(state, doctors) {
      state.entry.contacts.doctors = doctors;
    },
    clearDoctors(state) {
      state.entry.contacts.doctors = [];
    },
    // Frozen Menus
    setFrozenMenus(state, menus) {
      state.frozenMenus = menus;
    },
    // Districts
    setDistricts(state, districts) {
      state.districts = districts;
    },
    // Active/matched district
    setMatchedDistrict(state, district) {
      state.entry.district = district;
    },
    // Upcoming Hot
    setUpcomingHotMeals(state, meals) {
      state.upcomingHotMeals = meals;
    },
    // Meals
    addMeal(state, payload) {
      // find or create order group
      let group =
        find(state.entry.order.weekly.groups, group => {
          return payload.menu.id === group.menu.id;
        }) ||
        // group not found. Array push, and then use the returned length of that
        // operation to get and set the newly pushed group.
        state.entry.order.weekly.groups[
          state.entry.order.weekly.groups.push({
            menu: payload.menu,
            meals: [],
            delivery: {
              date: null
            }
          }) - 1
        ];

      // find or create the meal in the group
      let meal =
        find(group.meals, meal => {
          return payload.meal.id === meal.meal.id;
        }) ||
        // meal not found. Push and retrieve the meal
        group.meals[
          group.meals.push({
            meal: payload.meal,
            quantity: 0 // we will set quantity in the next step
          }) - 1
        ];

      if (parseInt(payload.quantity)) {
        meal.quantity = parseInt(meal.quantity) + parseInt(payload.quantity);
      }
    },
    // Subscription Order
    setDailyOrder(state, order) {
      state.entry.order.daily = {
        ...{
          delivery: {
            date: null
          }
        },
        ...order
      };
    },
    clearDailyOrder(state) {
      state.entry.order.daily = {};
    },
    setWeeklyOrderGroupDeliveryDate(state, payload) {
      let group = find(state.entry.order.weekly.groups, group => {
        return payload.group.menu.id === group.menu.id;
      });
      group.delivery.date = payload.date;
    },
    setDailyOrderDeliveryDate(state, date) {
      state.entry.order.daily.delivery.date = date;
    },
    setWeeklyDrinkType(state, drink) {
      state.entry.order.weekly.drink = drink;
    },
    setWeeklyRecurringOptions(state, options) {
      state.entry.order.weekly.recurringOptions = options;
    },
    createStripeClient(state) {
      state.stripe.client = Stripe(process.env.VUE_APP_STRIPE_KEY);
    },
    createStripeElements(state) {
      state.stripe.elements = state.stripe.client.elements();
    },
    setStripeClientSecret(state, secret) {
      state.stripe.clientSecret = secret;
    },
    createStripeDomElements(state) {
      const style = {
        base: {
          color: "#32325d",
          fontFamily: "Arial, sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          fontFamily: "Arial, sans-serif",
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      state.stripe.card = state.stripe.elements.create("card", { style: style });
      // Stripe injects an iframe into the DOM
      state.stripe.card.mount("#card-element");
      state.stripe.card.on("change", function(event) {
        state.stripe.cardState.disabled = event.empty;
        state.stripe.cardState.error = event.error ? event.error.message : "";
      });
    },
    updateOrderedMealQuantity(state, payload) {
      let group = find(state.entry.order.weekly.groups, group => {
        return group.menu.id === payload.menu.id;
      });
      let meal = find(group.meals, meal => {
        return meal.meal.id === payload.meal.meal.id;
      });
      meal.quantity = parseInt(payload.quantity, 10);
    },
    removeOrderedMeal(state, payload) {
      // Create new object we can mutate
      let groups = cloneDeep(state.entry.order.weekly.groups);
      // There can be multiple groups, aka menus. Find the one we want to remove the meal from.
      let group = find(groups, group => {
        return group.menu.id === payload.menu.id;
      });
      // Mutate the array and remove the matched meal.
      remove(group.meals, addedMeal => {
        return addedMeal.meal.id === payload.meal.meal.id;
      });
      // Set the mutated array to trigger view updates
      state.entry.order.weekly.groups = groups;
    },
    setSuccessfulPaymentIntentId(state, id) {
      state.entry.paymentIntentId = id;
    },
    setBusinessDays(state, days) {
      state.businessDays = days;
    },
    setFirstAvailableDailyDelivery(state, day) {
      state.firstAvailableDayDailyDelivery = day;
    },
    setFirstAvailableWeeklyDelivery(state, day) {
      state.firstAvailableDayWeeklyDelivery = day;
    },
    setReferrer(state, data) {
      if (data.other !== '') {
        const idx = data.choices.indexOf('Other');
        data.choices[idx] =`${data.choices[idx]}: ${data.other}`;
      }
      state.entry.referrer = data.choices;
    },
  },
  actions: {
    indexFrozenMenus(context) {
      context.commit("setLoading", true);
      fetch(`${process.env.VUE_APP_API_URL}/menus`)
        .then(r => r.json())
        .then(data => {
          context.commit("setFrozenMenus", data);
          context.commit("setLoading", false);
        });
    },
    indexDistricts(context) {
      fetch(`${process.env.VUE_APP_API_URL}/districts`)
        .then(r => r.json())
        .then(data => context.commit("setDistricts", data));
    },
    indexAllinaDistricts(context) {
      fetch(`${process.env.VUE_APP_API_URL}/allina_districts`)
        .then(r => r.json())
        .then(data => context.commit("setDistricts", data));
    },
    indexUpcomingHotMeals(context) {
      fetch(`${process.env.VUE_APP_API_URL}/meals/hot/upcoming`, {
        headers: {
          Accept: "application/json"
        }
      })
        .then(r => r.json())
        .then(data => context.commit("setUpcomingHotMeals", data));
    },
    submit({ state, getters, dispatch }) {
      let allina = false;
      let uri = window.location.search;
      let params = new URLSearchParams(uri);
      if(params.get("app_version") && params.get("app_version") === "allina") {
        allina = true;
      }

      console.log(`initiating ServTracker requests!`);
      dispatch('subServTracker');

      return fetch(`${process.env.VUE_APP_API_URL}/submissions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          id: state.id,
          entry: {
            ...state.entry,
            ...{
              price: getters.getPurchasePrice,
              allina: allina
            }
          }
        })
      }).then(r => r.json());
    },
    createStripeClientSecret({ state, commit, getters }) {
      return fetch(`${process.env.VUE_APP_API_URL}/payment-intent`, {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: JSON.stringify({
          amount: getters.getPurchasePrice,
          id: state.id,
          program: state.entry.district.district,
          recipientName: state.entry.personalInformation.firstName + " " + state.entry.personalInformation.lastName,
          payerName: state.entry.filler.name
        })
      })
        .then(response => response.json())
        .then(data => {
          commit("setStripeClientSecret", data.clientSecret);
        });
    },
    completeStripePayment({ state }) {
      return state.stripe.client.confirmCardPayment(state.stripe.clientSecret, {
        payment_method: {
          card: state.stripe.card
        }
      });
    },
    indexBusinessDays({ commit }) {
      return fetch(`${process.env.VUE_APP_API_URL}/business-days`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then(response => response.json())
        .then(data => {
          commit(
            "setBusinessDays",
            data.days.map(day => day.day)
          );
        });
    },
    getFirstAvailableDailyDeliveryDate({ commit }) {
      return getBusinessDayOffset(dailyLeadDays)
        .then(response => response.json())
        .then(data => {
          commit(
            "setFirstAvailableDailyDelivery",
            adjustUtcForCurrentTimezone(parse(data.date, "yyyy-MM-dd HH:mm:ss xxx", new Date()))
          );
        });
    },
    getFirstAvailableWeeklyDeliveryDate({ commit }) {
      return getBusinessDayOffset(weeklyLeadDays)
        .then(response => response.json())
        .then(data => {
          commit(
            "setFirstAvailableWeeklyDelivery",
            adjustUtcForCurrentTimezone(parse(data.date, "yyyy-MM-dd HH:mm:ss xxx", new Date()))
          );
        });
    },
    async subServTracker({ state }) {
      const auth_token = fetch(`${process.env.VUE_APP_API_URL}/servTracker/auth/token`)
        .then(response => response.json())
        .then(data => {
          return data.access_token;
        })
        .catch(err => console.error(`Error fetching servTracker access token: `, err));
      const access_token = await auth_token;

      fetch(`${process.env.VUE_APP_API_URL}/servTracker/addUpdate`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${access_token}`
        },
        body: JSON.stringify(state.entry)
      })
        .then(r => r.text())
        .then(data => {
          return data;
        })
        .catch(err => console.error(`Error submitting to servTracker: `, err));
    }
  }
});

function getBusinessDayOffset(days) {
  return fetch(`${process.env.VUE_APP_API_URL}/upcoming-business-day/${days}`, {
    method: "GET",
    headers: {
      Accept: "application/json"
    }
  });
}
