<template>
  <div class="max-w-2xl mx-auto">
    <h1>
      {{ $t(`review.${$store.getters.subject}.title`) }}
    </h1>

    <h3 class="mt-4">{{ $t("interface.deliveryAddress") }}</h3>
    {{ formattedAddress(entry.addresses.mailing) }}

    <div v-if="entry.hasBillingAddress">
      <h3 class="mt-4">{{ $t("interface.billingAddress") }}</h3>
      {{ formattedAddress(entry.addresses.billing) }}
    </div>

    <h3 class="mt-4">{{ $t("interface.order") }}</h3>

    <Summary :show-delivery="true" />

    <div v-if="$store.getters.isPrivatePaymentType">
      <h3 class="mt-4">{{ $t("interface.payment") }}</h3>
      <ul>
        <li>{{ upperFirst(startCase(entry.paymentMethod)) }}</li>
        <li v-if="$store.getters.isCreditCardPayment">
          {{ formatNumberToUsd($store.getters.getPurchasePrice) }}
          <span v-if="$store.getters.isOrderRepeating"> {{ $t("review.mealsPerWeek") }}.</span>
          <span v-else> {{ $t("review.oneTime") }}.</span>
        </li>
      </ul>
    </div>

    <div v-if="$store.getters.isWaiverPaymentType && !appVersion.allina">
      <h3 class="mt-4">{{ $t("review.waiver.title") }}</h3>
      <ul>
        <li>{{ $t("review.waiver.payer") }}: {{ $store.state.entry.waiverInformation.payer }}</li>
        <li>{{ $t("review.waiver.waiver") }}: {{ $store.state.entry.waiverInformation.waiver }}</li>
        <li v-if="$store.state.entry.waiverInformation.pmi">
          {{ $t("review.waiver.medicaidPmi") }}: {{ $store.state.entry.waiverInformation.pmi }}
        </li>
        <li v-if="$store.state.entry.waiverInformation.payerId">
          {{ $t("review.waiver.payerId") }}: {{ $store.state.entry.waiverInformation.payerId }}
        </li>
        <li v-if="$store.state.entry.waiverInformation.informationAboutAdditionalCovidRelief === 'Yes'">
          {{ $t("review.waiver.additionalCovidReliefInfo") }}f
        </li>
        <li v-if="$store.state.entry.waiverInformation.spenddownOrWaiverObligation === 'Yes'">
          {{ $t("review.waiver.spenddownOrWaiver") }}
        </li>
        <li v-if="$store.state.entry.waiverInformation.serviceAgreementAuthorizationRequested === 'Yes'">
          {{ $t("review.waiver.serviceAgreementAuthRequested") }}
        </li>
        <li>
          {{ $store.state.entry.waiverInformation.numberOfMealsAuthorized }} {{ $t("review.waiver.mealsAuthorized") }}.
        </li>
        <li>
          {{ $t("review.program.name") }}: {{ this.$store.state.entry.district.data.district_name }}
        </li>
        <li>
          {{ $t("review.program.phone") }}: {{ this.$store.state.entry.district.data.district_phone[0] }}
        </li>
        <li>
          {{ $t("review.program.number") }}: {{ this.$store.state.entry.district.data.district_npi[0] }}
        </li>
      </ul>
    </div>

    <h3 class="mt-4">
      {{ $t(`review.${$store.getters.subject}.information`) }}
    </h3>
    <ul>
      <li>
        {{ $t("interface.name") }}: {{ entry.personalInformation.firstName }} {{ entry.personalInformation.lastName }}
      </li>
      <li>{{ $t("interface.email") }}: {{ entry.contactInformation.email }}</li>
      <li>{{ $t("interface.phone") }}: {{ entry.contactInformation.phone }}</li>
    </ul>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :showLoading="true" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
import { format, addMinutes, subMinutes } from "date-fns";
import { upperFirst, startCase } from "lodash";
import Summary from "./../order/components/Summary";

export default {
  name: "Review",
  components: { Summary },
  data: () => ({
    appVersion: {
      allina: false
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  computed: {
    entry: function() {
      return this.$store.state.entry;
    }
  },
  methods: {
    formattedAddress(address) {
      return address.street + ", " + address.city + " " + address.state + " " + address.postal;
    },
    getReadableStringFromDate: function(isoString) {
      // isoString we get has no timezone. When it gets created, the internal date is considered
      // midnight UTC. When we display the date in the browser, the timezone gets factored in,
      // and you can roll over into the previous day.
      let date = new Date(isoString);
      // This will get the number of minutes we are currently off from UTC.
      let adjustment = date.getTimezoneOffset();
      // We need to add those minutes to the UTC date so that when we display the date, it
      // will show up correctly.
      if (adjustment > 0) {
        date = addMinutes(date, adjustment);
      } else {
        date = subMinutes(date, Math.abs(adjustment));
      }
      return format(date, "MMMM do");
    },
    next() {
      this.$emit("scrollTop");
      this.$emit("next");
      if(this.appVersion.allina) {
        this.$emit("next");
        this.$store.commit("setLoading", true);
        this.$store.dispatch("submit").then(() => {
          this.$store.commit("setLoading", false);
          this.$emit("scrollTop");
          this.$emit("next");
        });
      }
    },
    formatNumberToUsd(number) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(number);
    },
    upperFirst,
    startCase
  }
};
</script>

<style></style>
