<template>
  <div class="max-w-4xl mx-auto">
    <div class="mb-8"><h2>{{ $t("continueBy.contact") }} {{$store.state.entry.district.district}}</h2></div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <a @click="$emit('next')">
        <div class="bg-white rounded border-solid border-gray-500 border shadow-md p-8 h-full">
          <h1 class="mb-2">{{ $t("continueBy.online") }}:</h1>
          <v-btn class="mt-8" color="primary">{{ $t("interface.continue") }}</v-btn>
        </div>
      </a>
      <a href="tel:612-623-3363">
        <div class="bg-white rounded border-solid border-gray-500 border shadow-md p-8">
          <h1>{{ $t("continueBy.phone") }}:</h1>
          <div class="mt-8"><h2>(612) 623-3363</h2></div>
        </div>
      </a>
    </div>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="$emit('next')" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>
<script>


export default {
  name: "Continue",
  components: {},
  data: () => ({}),
  computed: {
    entry: function() {
      return this.$store.state.entry;
    }
  },
  methods: {
    next: function() {
      this.$emit("scrollTop");
      this.$emit("next");
    }
  }

};
</script>

<style></style>
