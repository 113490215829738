<template>
  <div>
    <div v-if="$store.getters.isDailyOrder">
      <ul>
        <li v-if="showDelivery">
          {{ $t("summary.daily.delivery", { date: getReadableStringFromDate(entry.order.daily.delivery.date) }) }}
        </li>
        <li>
          {{ $t("summary.daily.perDay", { count: entry.order.daily.mealsPerDay }) }}
          {{ Object.values(entry.order.daily.daysOfWeekToReceive)
            .join(", ") }}
          each week
        </li>
        <li v-if="entry.order.daily.dietaryPreferences.length > 0">
          Meals are
          {{
            entry.order.daily.dietaryPreferences
              .join(", ")
              .toLowerCase()
          }}
        </li>
        <li v-if="appVersion.allina">Up to 14 meals will be delivered free of charge. Your local Meals on Wheels program will follow up with you on how to continue receiving meals.</li>
      </ul>
      <p></p>
    </div>

    <div v-if="$store.getters.isWeeklyOrder">
      <div v-for="group in $store.getters.orderGroups" v-bind:key="group.menu.id">
        <span v-if="showDelivery">
          {{ $t("summary.weekly.delivery", { date: getReadableStringFromDate(group.delivery.date) }) }}
        </span>
        <ul v-if="!editable">
          <li v-for="meal in group.meals" v-bind:key="meal.id">{{ meal.quantity }} x {{ meal.meal.name }}</li>
        </ul>
        <div v-else>
          <WeeklyOrderedItems />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, addMinutes, subMinutes } from "date-fns";
import WeeklyOrderedItems from "./WeeklyOrderedItems";

export default {
  name: "Summary",
  props: ["showDelivery", "editable"],
  components: {
    WeeklyOrderedItems
  },
  data: () => ({
    appVersion: {
      allina: false
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  methods: {
    getReadableStringFromDate: function(isoString) {
      // isoString we get has no timezone. When it gets created, the internal date is considered
      // midnight UTC. When we display the date in the browser, the timezone gets factored in,
      // and you can roll over into the previous day.
      let date = new Date(isoString);
      // This will get the number of minutes we are currently off from UTC.
      let adjustment = date.getTimezoneOffset();
      // We need to add those minutes to the UTC date so that when we display the date, it
      // will show up correctly.
      if (adjustment > 0) {
        date = addMinutes(date, adjustment);
      } else {
        date = subMinutes(date, Math.abs(adjustment));
      }
      return format(date, "MMMM do");
    }
  },
  computed: {
    entry: function() {
      return this.$store.state.entry;
    }
  }
};
</script>

<style></style>
