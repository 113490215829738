<template>
  <div class="max-w-4xl mx-auto">
    <h1>
      {{ $t(`doctors.${$store.getters.subject}.title`) }}
    </h1>
    <v-form v-model="valid" class="mt-6 mb-8">
      <div v-for="(doctor, index) in doctors" :key="index">
        <div class="flex -mx-2 items-center pb-4">
          <!-- Temporarily removing Add Doctors feature
          <div class="px-2 w-10">
            <p class="whitespace-no-wrap pb-4"># {{ index + 1 }}</p>
          </div>
          -->
          <div class="grid grid-cols-1 md:grid-cols-2">
            <v-text-field
              class="px-2"
              v-model.trim="doctor.name"
              :rules="rules.name"
              label="Name"
              required
              outlined
            ></v-text-field>
            <v-text-field
              class="px-2"
              v-model="doctor.phone"
              v-mask="'###-###-####'"
              :rules="rules.phone"
              label="Phone"
              required
              outlined
            ></v-text-field>
          </div>
          <!-- Temporarily removing Add Doctors feature
          <div class="px-2">
            <v-btn fab dark small class="mb-8" @click="remove(index)">
              <svg fill="currentColor" class="w-6 h-6" viewBox="0 0 20 20">
                <path
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </v-btn>
          </div>
          -->
        </div>
      </div>
      <!--
      <v-btn @click="add" color="primary" class="ml-10 bg-white" outlined :disabled="doctors.length >= 1">{{
        $t("doctors.add")
      }}</v-btn>
      -->
    </v-form>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :nextDisabled="!isValid" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Doctors",
  components: {},
  data: () => ({
    valid: false,
    doctors: [
      {
        name: "",
        phone: ""
      }
    ],
    rules: {
      name: [v => !!v || "A name is required"],
      phone: [v => !!v || "A phone number is required"]
    }
  }),
  methods: {
    add: function() {
      this.doctors.push({
        name: "",
        phone: ""
      });
    },
    remove: function(index) {
      this.doctors.splice(index, 1);
    },
    isValid: function() {
      if (this.$store.isWaiverPaymentType){
        return true;
      }
      return this.valid;
    },
    next: function() {
      this.$store.commit("setDoctors", this.doctors);
      this.$emit("scrollTop");
      this.$emit("next");
    }
  }
};
</script>

<style></style>
