import Vue from "vue";
import { store } from "./store";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./plugins/v-mask";
import i18n from "./plugins/vue-il8n";
import * as VueGoogleMaps from "vue2-google-maps";
import "./styles.css";
import StepNavigation from "./components/steps/support/StepNavigation";

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: `${process.env.VUE_APP_GOOGLE_MAP_KEY}`,
    libraries: "geometry",
    installComponents: true,
    loading: "async",
  },
});

// Set our globals
Vue.component("StepNavigation", StepNavigation);

new Vue({
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
