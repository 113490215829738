<template>
    <div class="w-24 md:w-32 absolute top-2 right-0 mr-4 language-selector-main">
        <v-select :items="languages" label="Language" outlined :value="locale" @change="setLanguage" dense></v-select>
    </div>
</template>

<script>
import i18n from "./../../../plugins/vue-il8n";

export default {
  name: "LanguageSelector",
  components: {},
  data: () => ({
    languages: [
      {
        text: "English",
        value: "en"
      },
      {
        text: "Español",
        value: "es"
      },
      {
        text: "Somali",
        value: "so"
      }
    ],
    locale: i18n.locale
  }),
  methods: {
    setLanguage(language) {
      i18n.locale = language;
    }
  },
  computed: {
    language: () => {
      return i18n.locale;
    }
  }
};
</script>
