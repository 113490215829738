<template>
  <div class="max-w-2xl mx-auto">
    <h1>{{ $t(`paymentMethod.${$store.getters.subject}.title`) }}</h1>
    <v-radio-group v-if="$store.getters.acceptsCreditCard" v-model="paymentMethod">
      <v-radio
        v-if="$store.getters.isPrivatePaymentType"
        :label="$t('interface.paymentMethods.creditCard')"
        value="creditCard"
      ></v-radio>
      <v-radio
        v-if="$store.getters.isPrivatePaymentType"
        :label="$t('interface.paymentMethods.check')"
        value="check"
      ></v-radio>
      <v-radio
        v-if="$store.getters.isWaiverPaymentType"
        label="Medicare or Medicaid"
        value="medicareOrMedicaid"
      ></v-radio>
    </v-radio-group>
    <p v-else class="text-md mt-4">{{ $t(`paymentMethod.${$store.getters.subject}.noCC`) }}</p>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :nextDisabled="!valid" v-on:previous="$emit('previous')" />
    </v-footer>

    <!-- <div>
      <v-alert color="#F5AA01" type="info">
        <PaymentOptionHelp type="link">
          {{ $t("paymentHelp.button") }}
        </PaymentOptionHelp>
      </v-alert>
    </div> -->
  </div>
</template>

<script>
// import PaymentOptionHelp from "./../support/PaymentOptionHelp";
export default {
  name: "PaymentMethod",
  components: {
    // PaymentOptionHelp,
  },
  data: () => ({
    paymentMethod: "",
    appVersion: {
      allina: false
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  computed: {
    valid: function() {
      return !this.$store.getters.acceptsCreditCard || this.paymentMethod;
    }
  },
  methods: {
    next: function() {
      this.$store.commit("setPaymentMethod", this.paymentMethod);
      this.$emit("scrollTop");
      this.$emit("next");
    }
  }
};
</script>

<style></style>
