<template>
  <div>
    <div class="header__nav nav-float" aria-hidden="true" tabindex="-1">
      <div class="inner flex site-width relative">
        <a class="mobile-logo-container z-index-1" :href="vueAppSiteUrl + ''" tabindex="-1">
          <img class="mobile-logo" src="/images/mow-logo.png" alt="Metro Meals on Wheels" />
        </a>
        <div class="aux-nav">
          <nav id="aux-nav" class="user-menu">
            <div class="no-list">
              <ul id="menu-user-menu" class="menu">
                <li
                  id="menu-item-47722"
                  class="phone lilita logged-in menu-item menu-item-type-custom menu-item-object-custom menu-item-47722"
                >
                  <a href="tel:6126233363" tabindex="-1">(612) 623-3363</a>
                </li>
                <li
                  id="menu-item-830"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-830"
                >
                  <a :href="vueAppSiteUrl + '/members/'" tabindex="-1"
                    >Members<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                  ></a>
                  <ul class="sub-menu">
                    <li
                      id="menu-item-831"
                      class="
                        menu-item
                        menu-item-type-post_type
                        menu-item-object-page menu-item-has-children menu-item-831
                      "
                    >
                      <a :href="vueAppSiteUrl + '/members/photo-library/'" tabindex="-1"
                        >Photo Library<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                      ></a>
                      <ul class="sub-menu">
                        <li
                          id="menu-item-832"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-832"
                        >
                          <a :href="vueAppSiteUrl + '/members/photo-library/clients/'" tabindex="-1">Clients</a>
                        </li>
                        <li
                          id="menu-item-835"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-835"
                        >
                          <a :href="vueAppSiteUrl + '/members/photo-library/food/'" tabindex="-1">Food</a>
                        </li>
                        <li
                          id="menu-item-834"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-834"
                        >
                          <a :href="vueAppSiteUrl + '/members/photo-library/volunteers/'" tabindex="-1"
                            >Volunteers</a
                          >
                        </li>
                        <li
                          id="menu-item-833"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-833"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/photo-library/guide-for-using-photos/'"
                            tabindex="-1"
                            >Guide for Using Photos</a
                          >
                        </li>
                      </ul>
                    </li>
                    <li
                      id="menu-item-838"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-838"
                    >
                      <a :href="vueAppSiteUrl + '/members/video-library/'" tabindex="-1">Video Library</a>
                    </li>
                    <li
                      id="menu-item-842"
                      class="
                        menu-item
                        menu-item-type-post_type
                        menu-item-object-page menu-item-has-children menu-item-842
                      "
                    >
                      <a :href="vueAppSiteUrl + '/members/resource-library/'" tabindex="-1"
                        >Resource Library<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                      ></a>
                      <ul class="sub-menu">
                        <li
                          id="menu-item-9441"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-9441"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/disaster-preparedness-resources/'"
                            tabindex="-1"
                            >Disaster Preparedness Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-1730"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1730"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/kitchen-opportunities-resources/'"
                            tabindex="-1"
                            >Meals on Wheels Kitchen Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-843"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-843"
                        >
                          <a :href="vueAppSiteUrl + '/members/resource-library/client-resources/'" tabindex="-1"
                            >Client Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-844"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-844"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/fundraising-resources/'"
                            tabindex="-1"
                            >Fundraising Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-845"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-845"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/governance-resources/'"
                            tabindex="-1"
                            >Governance Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-846"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-846"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/meal-delivery-resources/'"
                            tabindex="-1"
                            >Meal Delivery Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-847"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-847"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/member-tools-and-resources/'"
                            tabindex="-1"
                            >Member Tools and Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-848"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-848"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/outreach-resources/'"
                            tabindex="-1"
                            >Outreach Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-849"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-849"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/program-operations-resources/'"
                            tabindex="-1"
                            >Program Operations Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-850"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-850"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/volunteer-resources/'"
                            tabindex="-1"
                            >Volunteer Resources</a
                          >
                        </li>
                        <li
                          id="menu-item-851"
                          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-851"
                        >
                          <a
                            :href="vueAppSiteUrl + '/members/resource-library/walk-to-end-hunger-resources/'"
                            tabindex="-1"
                            >Walk to End Hunger Resources</a
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li id="menu-item-727" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-727">
                  <a href="/wp-login.php?action=logout" tabindex="-1">Logout</a>
                </li>
                <li
                  id="menu-item-42461"
                  class="donate menu-item menu-item-type-custom menu-item-object-custom menu-item-42461"
                >
                  <a href="/donate/donate-now" tabindex="-1">Donate</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="nav-container">
          <div id="fixed-primary" class="flex space-between">
            <nav class="main-nav">
              <ul id="menu-main-menu" class="menu">
                <li
                  id="menu-item-134"
                  class="
                    donate-menu-item
                    menu-item
                    menu-item-type-post_type
                    menu-item-object-page menu-item-has-children menu-item-134
                  "
                >
                  <a :href="vueAppSiteUrl + '/donate/'" tabindex="-1"
                    >Donate<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                  ></a>
                  <span class="toggle-submenu pointer absolute flex align-items-center justify-center"
                    ><i class="fa fa-caret-down white"></i
                  ></span>
                </li>
                <li
                  id="menu-item-46862"
                  class="
                    get-meals-menu-item
                    menu-item
                    menu-item-type-post_type
                    menu-item-object-page menu-item-has-children menu-item-46862
                  "
                >
                  <a :href="vueAppSiteUrl + '/get-meals/'" tabindex="-1"
                    >Get Meals<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                  ></a>
                  <span class="toggle-submenu pointer absolute flex align-items-center justify-center"
                    ><i class="fa fa-caret-down white"></i
                  ></span>
                </li>
                <li
                  id="menu-item-46866"
                  class="
                    menu-item
                    menu-item-type-post_type
                    menu-item-object-page
                    current-menu-item
                    page_item page-item-46827
                    current_page_item
                    menu-item-has-children menu-item-46866
                  "
                >
                  <a :href="vueAppSiteUrl + '/volunteer/'" aria-current="page" tabindex="-1"
                    >Get Involved<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                  ></a>
                  <span class="toggle-submenu pointer absolute flex align-items-center justify-center"
                    ><i class="fa fa-caret-down white"></i
                  ></span>
                </li>
                <li
                  id="menu-item-46865"
                  class="
                    menu-item
                    menu-item-type-post_type
                    menu-item-object-page menu-item-has-children menu-item-46865
                  "
                >
                  <a :href="vueAppSiteUrl + '/about/'" tabindex="-1"
                    >About<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                  ></a>
                  <span class="toggle-submenu pointer absolute flex align-items-center justify-center"
                    ><i class="fa fa-caret-down white"></i
                  ></span>
                </li>
                <li
                  id="menu-item-47865"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47865"
                >
                  <a :href="vueAppSiteUrl + '/contact/'" tabindex="-1">Contact</a>
                </li>
                <li
                  id="menu-item-47720"
                  class="mobile-phone hide menu-item menu-item-type-custom menu-item-object-custom menu-item-47720"
                >
                  <a href="tel:6126233363" tabindex="-1">(612) 623-3363</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <!-- *** MENU TOGGLE
					================================================================================= -->
        <div id="menu-toggle" class="menu-toggle relative pointer hide" aria-expanded="false">
          <div class="inner">
            <div class="white" style="background-color: #a0cf67 !important; cursor: pointer" @click="toggleMobileMenu()">
              MENU <i class="fa fa-caret-down white"></i>
            </div>
          </div>
        </div>

        <div class="mobile-donate hide">
          <a href="/donate" class="button bg-red" tabindex="-1">DONATE</a>
        </div>
      </div>
    </div>
    <header class="main-header z-index-5">
      <div class="site-width relative z-index-2">
        <a class="header-logo" :href="vueAppSiteUrl + ''"
          ><img class="main-logo" src="/images/mow-logo.png" alt="Metro Meals on Wheels"
        /></a>
      </div>

      <div class="site-width">
        <div class="aux-nav">
          <div style="display: flex; align-items: center; flex: 1 1 100%">
            <img
              class="serving-up-smiles-logo"
              src="/images/serving-up-smiles.png"
              alt="Serving Up Smiles"
              aria-hidden="true"
              width="208"
              height="56"
            />
            <nav id="aux-nav" class="user-menu">
              <div class="no-list">
                <ul id="menu-upper-menu-1" class="menu">
                  <li class="phone lilita menu-item menu-item-type-custom menu-item-object-custom menu-item-47721">
                    <a href="tel:6126233363">(612) 623-3363</a>
                  </li>
                  <li class="donate menu-item menu-item-type-custom menu-item-object-custom menu-item-42457">
                    <a :href="vueAppSiteUrl + '/donate/donate-now/'">Donate</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="primary-navigation relative z-index-1">
        <div class="site-width no-padding relative flex space-between">
          <nav class="main-nav no-list">
            <ul id="menu-main-menu-1" class="menu">
              <li
                class="
                  donate-menu-item
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page menu-item-has-children menu-item-134
                "
              >
                <a :href="vueAppSiteUrl + '/donate/'"
                  >Donate<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                ></a>
                <ul class="sub-menu">
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-137">
                    <a :href="vueAppSiteUrl + '/donate/donate-now/'">Donate Now</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-135">
                    <a :href="vueAppSiteUrl + '/donate/become-a-sustaining-donor/'">Monthly Donors</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142">
                    <a :href="vueAppSiteUrl + '/donate/donate-stock/'">Donate Stock</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-140">
                    <a :href="vueAppSiteUrl + '/donate/legacy-giving/'">Estate Donations</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-46917">
                    <a :href="vueAppSiteUrl + '/other-ways-to-give/'">Other Ways to Give</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-139">
                    <a :href="vueAppSiteUrl + '/donate/faqs-for-donors-to-meals-on-wheels/'">FAQ</a>
                  </li>
                </ul>
              </li>
              <li
                class="
                  get-meals-menu-item
                  menu-item
                  menu-item-type-post_type
                  menu-item-object-page
                  current-page-ancestor
                  current-menu-ancestor
                  current-menu-parent
                  current-page-parent
                  current_page_parent
                  current_page_ancestor
                  menu-item-has-children menu-item-46862
                "
              >
                <a :href="vueAppSiteUrl + '/get-meals/'"
                  >Get Meals<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                ></a>
                <ul class="sub-menu">
                  <li
                    class="
                      menu-item
                      menu-item-type-post_type
                      menu-item-object-page
                      current-menu-item
                      page_item page-item-38468
                      current_page_item
                      menu-item-46901
                    "
                  >
                    <a :href="vueAppSiteUrl + '/get-meals/sign-up/'" aria-current="page">Order Now</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-51956">
                    <a :href="vueAppSiteUrl + '/meals/'">Preview Our Meals</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-46918">
                    <a :href="vueAppSiteUrl + '/get-meals/individuals-and-caregivers/'">Learn More</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-116">
                    <a :href="vueAppSiteUrl + '/care-professionals/'">For Care Professionals</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-121">
                    <a
                      :href="vueAppSiteUrl + '/get-meals/individuals-and-caregivers/independent-living-resources/'"
                      >Older Adult Resources</a
                    >
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-46919">
                    <a :href="vueAppSiteUrl + '/about-the-meals/'">About the Meals</a>
                  </li>
                </ul>
              </li>
              <li
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-46866"
              >
                <a :href="vueAppSiteUrl + '/volunteer/'"
                  >Get Involved<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                ></a>
                <ul class="sub-menu">
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-125">
                    <a :href="vueAppSiteUrl + '/volunteer/individuals/'">Deliver Meals</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2890">
                    <a :href="vueAppSiteUrl + '/volunteer/volunteer-at-our-kitchen/'">Kitchen Volunteers</a>
                  </li>
                  <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-55261">
                    <a :href="vueAppSiteUrl + '/events/'">Events</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-133">
                    <a :href="vueAppSiteUrl + '/volunteer-old/families/other-family-volunteer-opportunities/'"
                      >Other Ways</a
                    >
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-46920">
                    <a :href="vueAppSiteUrl + '/volunteer/sign-up-to-volunteer/'">Sign Up</a>
                  </li>
                </ul>
              </li>
              <li
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-46865"
              >
                <a :href="vueAppSiteUrl + '/about/'"
                  >About<span class="submenu-arrow"><i class="fa fa-caret-down"></i></span
                ></a>
                <ul class="sub-menu">
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-114">
                    <a :href="vueAppSiteUrl + '/about/staff/'">Our Team</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-46902">
                    <a :href="vueAppSiteUrl + '/blog/'">Blog</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-113">
                    <a :href="vueAppSiteUrl + '/about/reports/'">Reports</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-112">
                    <a :href="vueAppSiteUrl + '/about/history/'">History</a>
                  </li>
                  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2410">
                    <a :href="vueAppSiteUrl + '/about/careers/'">Careers</a>
                  </li>
                </ul>
              </li>
              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-60756">
                <a :href="vueAppSiteUrl + '/contact/'">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>

  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data: () => ({
    mobileMenuOpen: false,
    vueAppSiteUrl: 'https://meals-on-wheels.com'
    // vueAppSiteUrl: (process.env.NODE_ENV === 'production') ? 'https://meals-on-wheels.com' : 'https://staging.mammoth-yard.flywheelsites.com'
  }),
  created: function () {},
  methods: {
    toggleMobileMenu() {
      const navContainers = document.getElementsByClassName("nav-container");
      if (this.mobileMenuOpen === false) {
        this.mobileMenuOpen = true;
        for (const element of Object.entries(navContainers)) {
          element[1].style.cssText += 'visibility: visible;opacity:1;';
        }
      } else {
        this.mobileMenuOpen = false;
        for (const element of Object.entries(navContainers)) {
          element[1].style.cssText += 'visibility: hidden;opacity:0;';
        }
      }
    },
  },
};
</script>

<style>
</style>
