<template>
  <div class="max-w-2xl mx-auto">
    <div v-if="$store.getters.isWeeklyOrder">
      <h1 class="mb-4">
        {{ $t(`weeklyDelivery.${$store.getters.subject}.title`) }}
      </h1>

      <div v-if="$store.getters.isWeeklyOrder" class="sm:float-right sm:w-1/2">
        <v-alert
            text
            outlined
            color="warning"
            icon="mdi-alert"
        >
          {{ $t("weeklyDelivery.availability") }}
        </v-alert>
      </div>

      <div v-for="group in $store.getters.orderGroups" v-bind:key="group.menu.id">
        <p class="mt-4 mb-1 font-bold">
          {{ $t("weeklyDelivery.forItems", { name: group.menu.name }) }}
          <GroupDelivery :group="group" />
        </p>
      </div>
    </div>

    <div v-if="$store.getters.isDailyOrder">
      <h1 class="mb-4">
        {{ $t(`dailyDelivery.${$store.getters.subject}.title`) }}
      </h1>

      <v-date-picker
        :value="$store.state.entry.order.daily.delivery.date"
        v-on:input="updateDailyDeliveryDate"
        :allowed-dates="$store.getters.isDateValidForDailyDelivery"
        show-adjacent-months
      ></v-date-picker>
    </div>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation
        v-on:next="next"
        :nextDisabled="!$store.getters.isDeliveryValid"
        v-on:previous="previous"
      />
    </v-footer>
  </div>
</template>

<script>
import GroupDelivery from "./components/GroupDelivery";

export default {
  name: "Delivery",
  components: {
    GroupDelivery
  },
  created: function() {
    //
  },
  data: () => ({}),
  methods: {
    next: function() {
      this.$emit("scrollTop");
      this.$emit("next");
    },
    updateDailyDeliveryDate: function(date) {
      this.$store.commit("setDailyOrderDeliveryDate", date);
    },
    previous: function() {
      this.$emit('previous');
      let uri = window.location.search;
      let params = new URLSearchParams(uri);
      if(params.get("app_version") && params.get("app_version") === "allina") {
        this.$emit('previous');
      }
    }
  }
};
</script>

<style></style>
