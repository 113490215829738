<template>
  <div class="max-w-xl mx-auto">
    <h1>{{ $t("weeklyDrink.title") }}</h1>

    <v-radio-group v-if="!appVersion.allina" :rules="rules.required" v-model="beverage">
      <v-radio
        v-for="beverage in [
          'onePercentMilk',
          'skimMilk',
          'appleJuice',
          'orangeJuice',
          'grapeJuice',
          'noBeverage',
          'varietyBeverage',
        ]"
        :key="beverage"
        :label="$t(`interface.drinkOptions.${beverage}`)"
        :value="beverage"
      ></v-radio>
    </v-radio-group>
    <v-radio-group :rules="rules.required" v-model="beverage" v-if="appVersion.allina">
      <v-radio
        v-for="beverage in ['onePercentMilk', 'skimMilk', 'juice', 'noBeverage']"
        :key="beverage"
        :label="$t(`interface.drinkOptions.${beverage}`)"
        :value="beverage"
      ></v-radio>
    </v-radio-group>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation :nextDisabled="!beverage" v-on:next="next()" v-on:previous="previous" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "WeeklySelectDrink",
  components: {},
  data: () => ({
    beverage: "",
    rules: {
      required: [(v) => !!v || "This field is required"],
    },
    appVersion: {
      allina: false,
    },
  }),
  created: function () {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    if (params.get("app_version") && params.get("app_version") == "allina") {
      this.appVersion.allina = true;
    } else {
      this.appVersion.allina = false;
    }
  },
  methods: {
    next: function () {
      this.$store.commit("setWeeklyDrinkType", this.beverage);
      this.$emit("scrollTop");
      this.$emit("next");
    },
    previous: function() {
      this.$emit('previous');
      let uri = window.location.search;
      let params = new URLSearchParams(uri);
      if(params.get("app_version") && params.get("app_version") == "allina") {
        this.$emit('previous');
      }
    }
  },
};
</script>

<style></style>
