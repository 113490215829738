<template>
  <div>
    <v-form class="mt-8">
      <div class="flex -mx-2 items-center flex-wrap">
        <div class="px-2 w-1/3">
          <v-text-field
            label="Quantity"
            type="number"
            :value="quantity"
            @change="updateQuantity"
            required
            dense
            outlined
            min="1"
          ></v-text-field>
        </div>
        <div class="px-2 w-1/4">
          <v-btn
            @click="$emit('addmeal', { menu, meal, quantity });addedAlert();"
            class="mb-7"
            small
            color="secondary"
            >{{ $t('interface.add') }}</v-btn
          >
        </div>
      </div>
      <div v-if="!isHidden" class="text-sm red--text mt-n5">{{ $t('weeklyOrder.addedOrder') }}</div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "AddForm",
  props: ["menu", "meal"],
  components: {},
  data: () => ({
    quantity: 1,
    isHidden: true
  }),
  methods: {
    updateQuantity(quantity) {
      this.quantity = Math.abs(parseInt(quantity, 10))
    },
    addedAlert() {
      this.isHidden = false
    }
  },
};
</script>

<style></style>
