<template>
  <div>
    <v-alert v-if="!appVersion.allina" color="#F5AA01">
      <div class="flex-column align-center">
        <div class="col">
          {{ $t("paymentAssistanceRequest.banner.body") }}
        </div>
        <div class="col text-center">
          <PaymentAssistanceRequest type="button">{{
            $t("paymentAssistanceRequest.banner.cta")
          }}</PaymentAssistanceRequest>
        </div>
      </div>
    </v-alert>
    <div class="max-w-xl mx-auto">
      <h1>{{ $t("dailyOrder.title") }}</h1>

      <p class="text-md mt-4 text-red-error">Daily delivery schedules may vary by program area. Delivery days will be confirmed after submitting this form.</p>
      <v-form v-model="valid" class="mt-6 mb-2">
        <v-select
          :items="$t('interface.days')"
          v-model="daily.daysOfWeekToReceive"
          :rules="rules.selected"
          multiple
          :label="$t('dailyOrder.form.daysOfWeek')"
          outlined
          @change="sortDays"
        ></v-select>

        <p class="text-md mb-2" v-if="!appVersion.allina">
          {{ $t("interface.pricePerMeal") }}
          <strong>${{ $store.getters.getPerMealPrice }}</strong
          >.

          <PaymentOptionHelp v-if="!appVersion.allina" type="link">
            {{ $t("paymentHelp.button") }}
          </PaymentOptionHelp>
        </p>

        <v-select
          :items="[
            'Vegetarian',
            'Gluten Free',
            'Lactose Free',
            'Renal',
            'Diabetic Friendly',
            'Low Sodium',
            'Mechanical Soft',
            'Pureed',
            'Halal',
            'Kosher',
            'Traditional Hmong',
            'Latino-focused (Mexican/Latin American flavors)',
            'Other',
            'None',
          ]"
          v-model="daily.dietaryPreferences"
          multiple
          :label="$t('dailyOrder.form.dietaryPreferences')"
          outlined
        ></v-select>

        <v-text-field
          v-if="daily.dietaryPreferences === 'Other'"
          label="Other Dietary Preference"
          v-model.trim="daily.otherDietaryPreference"
          outlined
        ></v-text-field>

        <label>{{ $t("dailyOrder.form.beverageChoice") }}</label>
        <v-radio-group :rules="rules.required" v-model="daily.beverage" v-if="!appVersion.allina">
          <v-radio
            v-for="beverage in [
              'onePercentMilk',
              'skimMilk',
              'appleJuice',
              'orangeJuice',
              'grapeJuice',
              'noBeverage',
              'varietyBeverage',
            ]"
            :key="beverage"
            :label="$t(`interface.drinkOptions.${beverage}`)"
            :value="beverage"
          ></v-radio>
        </v-radio-group>
        <v-radio-group :rules="rules.required" v-model="daily.beverage" v-if="appVersion.allina">
          <v-radio
            v-for="beverage in ['onePercentMilk', 'skimMilk', 'juice', 'noBeverage']"
            :key="beverage"
            :label="$t(`interface.drinkOptions.${beverage}`)"
            :value="beverage"
          ></v-radio>
        </v-radio-group>
      </v-form>
      <v-footer color="primary lighten-1" app padless>
        <StepNavigation :nextDisabled="!valid" v-on:next="next()" v-on:previous="$emit('previous')" />
      </v-footer>
      <v-card class="mt-12 mb-2" v-if="!appVersion.allina">
        <v-card-text>
          <v-card-title>{{ $t("dailyOrder.previewUpcoming") }}</v-card-title>
          <v-card-text>
            <p class="font-bold text-sm text-red-error">
              {{ $t("dailyOrder.previewUpcomingNotice") }}
            </p>
            <UpcomingDailyMealsDialog />
          </v-card-text>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import PaymentOptionHelp from "./../support/PaymentOptionHelp";
import UpcomingDailyMealsDialog from "./components/UpcomingDailyMealsDialog";
import PaymentAssistanceRequest from "./../support/PaymentAssistanceRequest";

export default {
  name: "DailyOrder",
  components: {
    UpcomingDailyMealsDialog,
    PaymentOptionHelp,
    PaymentAssistanceRequest,
  },
  data: () => {
    return {
      valid: false,
      daily: {
        mealsPerDay: 1,
        daysOfWeekToReceive: [],
        dietaryPreferences: [],
        otherDietaryPreference: "",
        beverage: "",
      },
      rules: {
        required: [(v) => !!v || "This field is required"],
        selected: [(v) => v.length > 0 || "This field is required"],
      },
      appVersion: {
        allina: false,
      },
    };
  },
  created: function () {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  methods: {
    next: function () {
      this.$store.commit("setDailyOrder", this.daily);
      this.$emit("scrollTop");
      this.$emit("next");
    },
    sortDays: function (daysOfWeekToReceive) {
      const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
      this.daily.daysOfWeekToReceive = [];
      days.forEach((day) => {
        if (daysOfWeekToReceive.findIndex((k) => k === day) > -1) {
          this.daily.daysOfWeekToReceive.push(day);
        }
      });
    },
  },
};
</script>

<style></style>
