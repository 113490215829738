var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.appVersion.allina)?_c('v-alert',{attrs:{"color":"#F5AA01"}},[_c('div',{staticClass:"flex-column align-center"},[_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.$t("paymentAssistanceRequest.banner.body"))+" ")]),_c('div',{staticClass:"col text-center"},[_c('PaymentAssistanceRequest',{attrs:{"type":"button"}},[_vm._v(_vm._s(_vm.$t("paymentAssistanceRequest.banner.cta")))])],1)])]):_vm._e(),_c('div',{staticClass:"max-w-xl mx-auto"},[_c('h1',[_vm._v(_vm._s(_vm.$t("dailyOrder.title")))]),_c('p',{staticClass:"text-md mt-4 text-red-error"},[_vm._v("Daily delivery schedules may vary by program area. Delivery days will be confirmed after submitting this form.")]),_c('v-form',{staticClass:"mt-6 mb-2",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-select',{attrs:{"items":_vm.$t('interface.days'),"rules":_vm.rules.selected,"multiple":"","label":_vm.$t('dailyOrder.form.daysOfWeek'),"outlined":""},on:{"change":_vm.sortDays},model:{value:(_vm.daily.daysOfWeekToReceive),callback:function ($$v) {_vm.$set(_vm.daily, "daysOfWeekToReceive", $$v)},expression:"daily.daysOfWeekToReceive"}}),(!_vm.appVersion.allina)?_c('p',{staticClass:"text-md mb-2"},[_vm._v(" "+_vm._s(_vm.$t("interface.pricePerMeal"))+" "),_c('strong',[_vm._v("$"+_vm._s(_vm.$store.getters.getPerMealPrice))]),_vm._v(". "),(!_vm.appVersion.allina)?_c('PaymentOptionHelp',{attrs:{"type":"link"}},[_vm._v(" "+_vm._s(_vm.$t("paymentHelp.button"))+" ")]):_vm._e()],1):_vm._e(),_c('v-select',{attrs:{"items":[
          'Vegetarian',
          'Gluten Free',
          'Lactose Free',
          'Renal',
          'Diabetic Friendly',
          'Low Sodium',
          'Mechanical Soft',
          'Pureed',
          'Halal',
          'Kosher',
          'Traditional Hmong',
          'Latino-focused (Mexican/Latin American flavors)',
          'Other',
          'None',
        ],"multiple":"","label":_vm.$t('dailyOrder.form.dietaryPreferences'),"outlined":""},model:{value:(_vm.daily.dietaryPreferences),callback:function ($$v) {_vm.$set(_vm.daily, "dietaryPreferences", $$v)},expression:"daily.dietaryPreferences"}}),(_vm.daily.dietaryPreferences === 'Other')?_c('v-text-field',{attrs:{"label":"Other Dietary Preference","outlined":""},model:{value:(_vm.daily.otherDietaryPreference),callback:function ($$v) {_vm.$set(_vm.daily, "otherDietaryPreference", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"daily.otherDietaryPreference"}}):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t("dailyOrder.form.beverageChoice")))]),(!_vm.appVersion.allina)?_c('v-radio-group',{attrs:{"rules":_vm.rules.required},model:{value:(_vm.daily.beverage),callback:function ($$v) {_vm.$set(_vm.daily, "beverage", $$v)},expression:"daily.beverage"}},_vm._l(([
            'onePercentMilk',
            'skimMilk',
            'appleJuice',
            'orangeJuice',
            'grapeJuice',
            'noBeverage',
            'varietyBeverage',
          ]),function(beverage){return _c('v-radio',{key:beverage,attrs:{"label":_vm.$t(`interface.drinkOptions.${beverage}`),"value":beverage}})}),1):_vm._e(),(_vm.appVersion.allina)?_c('v-radio-group',{attrs:{"rules":_vm.rules.required},model:{value:(_vm.daily.beverage),callback:function ($$v) {_vm.$set(_vm.daily, "beverage", $$v)},expression:"daily.beverage"}},_vm._l((['onePercentMilk', 'skimMilk', 'juice', 'noBeverage']),function(beverage){return _c('v-radio',{key:beverage,attrs:{"label":_vm.$t(`interface.drinkOptions.${beverage}`),"value":beverage}})}),1):_vm._e()],1),_c('v-footer',{attrs:{"color":"primary lighten-1","app":"","padless":""}},[_c('StepNavigation',{attrs:{"nextDisabled":!_vm.valid},on:{"next":function($event){return _vm.next()},"previous":function($event){return _vm.$emit('previous')}}})],1),(!_vm.appVersion.allina)?_c('v-card',{staticClass:"mt-12 mb-2"},[_c('v-card-text',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("dailyOrder.previewUpcoming")))]),_c('v-card-text',[_c('p',{staticClass:"font-bold text-sm text-red-error"},[_vm._v(" "+_vm._s(_vm.$t("dailyOrder.previewUpcomingNotice"))+" ")]),_c('UpcomingDailyMealsDialog')],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }