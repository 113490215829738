<template>
  <div class="max-w-2xl mx-auto">
    <h1 class="mb-4 leading-tight">
      {{ $t(`orderReview.title`) }}
    </h1>

    <Summary :show-delivery="false" :editable="true"/>

    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :showLoading="true" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
import Summary from "./../order/components/Summary";

export default {
  name: "OrderReview",
  components: { Summary },
  data: () => ({}),
  methods: {
    next() {
      this.$emit("scrollTop");
      this.$emit("next");

      let uri = window.location.search;
      let params = new URLSearchParams(uri);
      if(params.get("app_version") && params.get("app_version") === "allina") {
        this.$store.commit("setPaymentType", "waiver");
        this.$store.commit("setRecipient", "other");
        this.$emit("next");
      }
    }
  }
};
</script>

<style></style>
