<template>
  <div class="max-w-xl mx-auto">
    <h1>{{ $t(`information.${$store.getters.subject}.title`) }}</h1>

    <v-form v-model="valid" class="mt-6 mb-2">
      <v-text-field
        v-model.trim="personalInformation.firstName"
        :rules="rules.firstName"
        :label="$t('interface.firstName')"
        required
        outlined
      ></v-text-field>
      <v-text-field
        v-model.trim="personalInformation.lastName"
        :rules="rules.lastName"
        :label="$t('interface.lastName')"
        required
        outlined
      ></v-text-field>
      <v-text-field
        v-if="!$store.getters.isCreditCardPayment"
        v-model="personalInformation.dateOfBirth"
        :rules="[dateOfBirth]"
        :label="$t('interface.dateOfBirth')"
        placeholder="mm/dd/yyyy"
        v-mask="'##/##/####'"
        hint="Examples: 01/01/1999, 12/31/1999"
        required
        outlined
      ></v-text-field>

      <p><small>We use demographic data to track the effectiveness of our program. Your answers to the following questions will not affect your ability to receive services.</small></p>
      <v-select
        v-model="personalInformation.military_veteran"
        :items="$t('interface.option')"
        :label="$t('interface.veteran')"
        :rules="rules.required"
        class="field-military_veteran"
        @change="clearErrors('field-military_veteran')"
        outlined
      ></v-select>

      <v-select
        v-if="!$store.getters.isCreditCardPayment"
        v-model="personalInformation.gender"
        :rules="rules.gender"
        :items="$t('interface.genders')"
        required
        :label="$t('interface.gender')"
        outlined
      ></v-select>

      <v-select
        v-if="!$store.getters.isCreditCardPayment"
        v-model="personalInformation.race"
        :rules="rules.race"
        :items="$t('paymentAssistanceRequest.ethnicity')"
        required
        multiple
        :label="$t('interface.race')"
        outlined
        persistent-hint
        class="field-race"
        @change="clearErrors('field-race')"
      ></v-select>

      <div class="mobile-field-label" v-if="!$store.getters.isCreditCardPayment">'* ' + {{ $t('interface.latin_label') }}</div>
      <v-select
          v-if="!$store.getters.isCreditCardPayment"
          v-model="personalInformation.ethnicity"
          :rules="rules.ethnicity"
          :items="$t('interface.latins')"
          required
          :label="'* ' + $t('interface.latin_label')"
          outlined
          persistent-hint
          class="field-ethnicity"
          @change="clearErrors('field-ethnicity')"
      ></v-select>

    </v-form>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :nextDisabled="!valid" v-on:previous="previous" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Information",
  components: {},
  data: () => ({
    valid: false,
    personalInformation: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      gender: "",
      race: "",
      ethnicity: "",
      military_veteran: ""
    },
    rules: {
      firstName: [v => !!v || "A first name is required"],
      lastName: [v => !!v || "A last name is required"],
      gender: [v => v.length > 0 || "A gender is required"],
      race: [v => v.length > 0 || "A race is required"] ,
      ethnicity: [v => v.length > 0 || "An ethnicity is required"]
    }
  }),
  methods: {
    next: function() {
      this.$store.commit("setPersonalInformation", this.personalInformation);
      this.$emit("scrollTop");
      this.$emit("next");
    },
    previous: function() {
      this.$emit('previous');
      let uri = window.location.search;
      let params = new URLSearchParams(uri);
      if(params.get("app_version") && params.get("app_version") === "allina") {
        this.$emit('previous');
      }
    },
    clearErrors: (classString) =>  {
      const fieldElements = document.getElementsByClassName(classString);
      for (const fieldElement of Object.entries(fieldElements)) {
        const inputSlots = fieldElement[1].getElementsByClassName("v-input__slot");
        for (const inputSlot of Object.entries(inputSlots)) {
          inputSlot[1].classList.remove("field-missing");
        }
      }
    },
    dateOfBirth: (value) => {
      const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[1-2][0-9]|3[01])\/\d{4}$/;
      if (value.search(regex) < 0) {
        return "A date of birth is required (mm/dd/yyyy)"
      } else {
        const dateArr = value.split("/");
        const dob = Math.floor(new Date(dateArr[2], dateArr[0] - 1, dateArr[1]).getTime() / 1000);
        const minDate = Math.floor(new Date(1900, 0, 1, 0,0,0,0) / 1000);
        const maxDate = new Date().setHours(0,0,0,0) / 1000;
        return (minDate <= dob && dob <= maxDate) || "Valid birthday must be between 01/01/1900 and today"
      }
    }
  }
};
</script>

<style></style>
