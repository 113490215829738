<template>
  <div class="max-w-4xl mx-auto">
    <h1>{{ $t('paymentType.title') }}</h1>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
      <div class="bg-white rounded border-solid border-gray-500 border shadow-md p-8">
        <p class="font-bold text-lg">{{ $t("paymentType.private.title") }}</p>
        <p>
          {{ $t("paymentType.private.body") }}
        </p>
        <v-btn class="mt-4" color="primary" @click="next('private')">{{ $t('interface.choose') }}</v-btn>
        <br /><br />
        <PaymentOptionHelp type="link" v-if="!appVersion.allina">
          {{ $t("paymentType.help.button") }}
        </PaymentOptionHelp>
      </div>
      <div class="bg-white rounded border-solid border-gray-500 border shadow-md p-8">
        <p class="font-bold text-lg">{{ $t("paymentType.waiver.title") }}</p>
        <p>
          {{ $t("paymentType.waiver.body") }}
        </p>
        <v-btn :disabled="$store.getters.getNumberOfMeals > 7" class="mt-4" color="primary" @click="next('waiver')"
          >{{ $t('interface.choose') }}</v-btn
        >
        <div v-if="$store.getters.getNumberOfMeals > 7">
          <p class="mt-4 font-bold">
            {{ $t('paymentType.overWaiverLimit') }}
          </p>
          <a class="block" href="#" @click="$emit('previous')">{{ $t('interface.back') }}</a>
        </div>
      </div>
    </div>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation :showNext="false" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
import PaymentOptionHelp from "./../support/PaymentOptionHelp";

export default {
  name: "PaymentType",
  components: {
    PaymentOptionHelp
  },
  data: () => ({
    paymentType: "",
    dialog: false,
    appVersion: {
      allina: false
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  computed: {
    valid: function() {
      return this.paymentType;
    }
  },
  methods: {
    next: function(type) {
      this.$store.commit("setPaymentType", type);
      if (type === "waiver") {
        // If it's a waiver payment, this is a case manager ordering for someone else. Set it here directly.
        this.$store.commit("setRecipient", "other");
      }
      this.$emit("scrollTop");
      this.$emit("next");
    }
  }
};
</script>

<style></style>
