<template>
  <div class="max-w-2xl mx-auto">
    <v-date-picker
      :value="group.delivery.date"
      v-on:input="updateGroupDeliveryDate"
      :allowed-dates="allowedDates"
    ></v-date-picker>
  </div>
</template>

<script>
export default {
  name: "GroupDelivery",
  props: ["group"],
  components: {},
  data: () => ({}),
  methods: {
    updateGroupDeliveryDate: function(date) {
      this.$store.commit("setWeeklyOrderGroupDeliveryDate", {
        group: this.group,
        date: date
      });
    },
    allowedDates(date) {
      return this.$store.getters.isDateValidForWeeklyDelivery(this.group.menu, date);
    }
  }
};
</script>

<style></style>
