<template>
  <div class="max-w-2xl mx-auto">
      <h1>
        {{ $t(`success.${$store.getters.subject}.title`) }}
      </h1>
      <p class="mt-2">
        {{ $t(`success.${$store.getters.subject}.body`) }}
      </p>
      <v-btn class="mt-4 mr-3" color="primary" @click="done()">{{ $t(`success.buttons.done`) }}</v-btn>
      <v-btn id="another" class="mt-4" color="primary" @click="another()">
        <span class="whitespace-normal">{{ $t(`success.buttons.another`) }}</span>
      </v-btn>
  </div>
</template>

<script>
export default {
  name: "Success",
  data: () => ({}),
  methods: {
    next: function() {
      this.$emit("scrollTop");
      this.$emit("next");
    },
    done() {
      window.location.href="https://meals-on-wheels.com/get-meals/";
    },
    another() {
      window.location.reload();
    },
    mounted() {
      window.scrollTo(0, 0);
    },
  }
};
</script>

<style></style>
