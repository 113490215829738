<template>
  <div class="mt-1 w-full mx-auto">
    <!-- <hr class="h-px text-gray-100 mb-4">  -->
    <div class="flex justify-space-between align-center bg-gray-300 p-3 rounded shadow">
      <div class="flex flex-column">
        <div class="text-left">
          <v-btn text small color="primary" href="tel:6126233363">
            <v-icon>mdi-phone</v-icon><span class="hidden sm:inline"> (612) 623-3363</span>
          </v-btn>
        </div>
      </div>
      <div class="flex flex-column text-center">
        <div class="justify-center">
          <v-btn v-if="showPrevious" class="mr-2 bg-white" outlined color="secondary" @click="previous">{{
              $t("interface.back")
            }}</v-btn>
          <v-btn v-if="showNext" :disabled="nextDisabled" color="primary" @click="next">
            {{ $t("interface.continue") }}
            <v-progress-circular
                v-show="showLoading && $store.getters.isLoading"
                class="ml-2"
                indeterminate
                color="primary"
                size="16"
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>
      <div class="flex flex-column">
        <div class="text-right">
          <v-btn text small color="primary" href="https://meals-on-wheels.com/contact/" target="_blank">
            <v-icon>mdi-email</v-icon><span class="hidden sm:inline"> Contact Us</span>
          </v-btn>
        </div>
      </div>
    </div>
    <!-- <hr class="h-px text-gray-100 mt-4">  -->
  </div>
</template>

<script>
export default {
  name: "StepNavigation",
  props: {
    showPrevious: {
      type: Boolean,
      default: true
    },
    showNext: {
      type: Boolean,
      default: true
    },
    nextDisabled: {
      type: Boolean,
      default: false
    },
    showLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  methods: {
    next() {
      this.$emit("scrollTop");
      this.$emit("next");
    },
    previous() {
      this.$emit("scrollTop");
      this.$emit("previous");
    },
    contact() {

    }
  }
};
</script>

<style></style>
