<template>
  <div>
    <div class="flex -mx-4 items-center flex-wrap">
      <div class="w-full lg:w-1/2 px-4 pb-8 lg:pb-0">
        <h1 class="text-2xl">
          {{ $t(`checkAddress.title`) }}
        </h1>
        <v-form v-model="valid" class="mt-6 mb-2">
          <div class="flex justify-between flex-wrap">
            <div class="w-full">
              <v-text-field
                class="w-full"
                v-model.trim="address.street"
                lazy
                :rules="rules.street"
                :label="$t('interface.streetAddress')"
                required
                outlined
              ></v-text-field>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-x-4">
            <div class="">
              <v-text-field
                class="w-full"
                v-model.trim="address.city"
                lazy
                :rules="rules.city"
                :label="$t('interface.city')"
                required
                outlined
              ></v-text-field>
            </div>
            <div class="">
              <v-text-field
                class="w-full"
                v-model.trim="address.state"
                lazy
                :rules="rules.state"
                :label="$t('interface.state')"
                required
                outlined
              ></v-text-field>
            </div>
            <div class="">
              <v-text-field
                class="w-full"
                v-model.trim="address.postal"
                lazy
                :rules="rules.postal"
                :label="$t('interface.postal')"
                required
                outlined
              ></v-text-field>
            </div>
          </div>
        </v-form>
        <div class="flex flex-col-reverse">
          <div class="mt-4">
            <div class="mr-4" v-if="processed && qualifies">
              <p class="text-green-success text-xl mb-0">{{ $t("checkAddress.success") }}</p>
            </div>
            <div class="mr-4" v-if="processed && !qualifies">
              <p class="text-red-error text-xl mb-0" v-html="$t('checkAddress.failure')"></p>
            </div>
          </div>
          <v-btn :disabled="!(processed && qualifies)" color="primary" @click="next">{{
            $t("interface.continue")
          }}</v-btn>
        </div>
      </div>
      <div class="hidden md:block w-full lg:w-1/2 px-4">
        <GmapMap
          ref="mapRef"
          :center="center"
          :zoom="zoom"
          :options="{
            zoomControl: false,
            scrollwheel: false,
            disableDoubleClickZoom: true,
            disableDefaultUI: true,
            maxZoom: 15,
            minZoom: 5
          }"
          style="width: 100%; height: 600px"
        >
          <GmapMarker v-if="this.coordinate" :position="this.coordinate" />
          <GmapPolygon
            v-if="matchedDistrict"
            :paths="matchedDistrict.coordinates"
            :options="{
              fillColor: matchedDistrict.color,
              strokeColor: matchedDistrict.color,
              fillOpacity: 0.35,
              strokeWeight: 1
            }"
          ></GmapPolygon>
        </GmapMap>
      </div>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import { debounce, find } from "lodash";

export default {
  name: "Address",
  components: {},
  data: () => ({
    valid: false,
    matchedDistrict: false,
    // Indicates if a match routine has been completed
    matched: false,
    geolocated: false,
    geocoder: null,
    coordinate: null,
    center: { lat: 44.961783, lng: -93.182485 },
    zoom: 8,
    address: {
      street: "",
      city: "",
      state: "",
      postal: ""
    },
    rules: {
      street: [v => !!v || "A street is required"],
      city: [v => !!v || "A city is required"],
      state: [v => !!v || "A state is required"],
      postal: [v => !!v || "A postal code is required"]
    },
    appVersion: {
      allina: false
    }
  }),
  mounted: function() {
    this.$store.dispatch("indexDistricts");
    let url = new URL(window.location.href);
    let postal = url.searchParams.get("postal");
    if (postal) {
      this.address.postal = postal;
      this.$gmapApiPromiseLazy().then(() => {
        this.geocoder = this.geocoder || new this.google.maps.Geocoder();
        const geocode = new Promise((resolve, reject) => {
          this.geocoder.geocode(
            { 'address': postal + ', US' },
            function(results, status) {
              if (status) {
                resolve(results.shift().address_components);
              }
              reject(status);
            }
          );
        });
        geocode
          .then(result => {
            if(typeof result[1] !== "undefined") {
              const components = {
                "locality": "city",
                "administrative_area_level_1": "state"
              };
              let address_components = [];
              for(const key  of Object.keys(components))
              {
                const idx = result.find(component => {
                  return component.types.indexOf(key) > -1
                });
                if (idx) {
                  address_components.push(idx);
                }
              }
              this.address.city = address_components[0].long_name;
              this.address.state = address_components[1].short_name;
            }
          });
      });
    }
  },
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  computed: {
    google: gmapApi,
    addressToString: function() {
      return this.address.street + " " + this.address.city + " " + this.address.state + " " + this.address.postal;
    },
    districts: function() {
      return this.$store.state.districts.map(district => {
        let coordinates = JSON.parse(district.coordinates);
        return {
          ...district,
          ...{
            coordinates: coordinates,
            // Create a polygon so we can run containsLocation against it
            polygon: new this.google.maps.Polygon({
              paths: coordinates
            })
          }
        };
      });
    },
    // Indicates if an address has been geolocated and if a match routine has been made.
    // This method is useful to toggling the show/hide state of the success/error message.
    processed: function() {
      return this.geolocated && this.matched;
    },
    // Is there a district match?
    qualifies: function() {
      return this.valid && this.matchedDistrict;
    }
  },
  watch: {
    address: {
      handler: debounce(function() {
        if (this.valid) {
          this.$store.commit("setLoading", true);
          this.geocoder = this.geocoder || new this.google.maps.Geocoder();
          const geocode = new Promise((resolve, reject) => {
            this.geocoder.geocode(
              {
                address: this.addressToString
              },
              function(results, status) {
                if (status) {
                  resolve(results.shift().geometry.location);
                }
                reject(status);
              }
            );
          });

          geocode
            .then(result => {
              this.coordinate = result;
              this.center = result;
              this.zoom = 14;
              this.matchedDistrict = this.findDistrictByPoint(result);
            })
            .finally(() => {
              // Indicate that at least one geocoding attempt has been made
              this.geolocated = true;
              this.$store.commit("setLoading", false);
            });
        }
      }, 250),
      deep: true
    }
  },
  methods: {
    // Attempts to match an available district given a lat/lng coordinate
    findDistrictByPoint(coordinate) {
      let matched = find(this.districts, district => {
        return this.google.maps.geometry.poly.containsLocation(coordinate, district.polygon);
      });

      // Check if district should redirect to old form
      if (matched && matched.use_legacy_signup_process) {
        let params = new URLSearchParams({
          street_address: this.address.street,
          city: this.address.city,
          state: "Minnesota",
          postal: this.address.postal
        });
        if(this.appVersion.allina === true) {
          params = new URLSearchParams({
            street_address: this.address.street,
            city: this.address.city,
            state: "Minnesota",
            postal: this.address.postal,
            app_version: "allina"
          });
        }
        window.top.location.href = process.env.VUE_APP_REDIRECT_LEGACY_URL + "?" + params.toString();
        return;
      }

      this.matched = true;
      return matched;
    },
    next: function() {

      // Once we have a matched address, then we can kick off some of our more computationally
      // intensive routines. Previously, this was being called when the application was first
      // instantiated. With that, any page load of the form was causing these methods to be
      // called, and it was causing more load on the WordPress and kitchen APIs than was
      // strictly required.
      this.$store.dispatch("indexBusinessDays");
      this.$store.dispatch("getFirstAvailableDailyDeliveryDate");
      this.$store.dispatch("getFirstAvailableWeeklyDeliveryDate");
      this.$store.dispatch("indexFrozenMenus");
      this.$store.dispatch("indexUpcomingHotMeals");

      // Set our data from this step and continue to next step
      this.$store.commit("setMailingAddress", this.address);
      this.$store.commit("setMatchedDistrict", this.matchedDistrict);

      this.$emit('scrollTop');
      this.$emit("next");
    }
  }
};
</script>

<style></style>
