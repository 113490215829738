import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './../il8n/mow-ordering-tool-en-US.json';
import es from './../il8n/mow-ordering-tool-es-MX.json';
import so from './../il8n/mow-ordering-tool-so-SO.json';

Vue.use(VueI18n)

let locale = window.location.pathname.replace(/^\/([^\\/]+).*/i,'$1');

export default new VueI18n({
    locale: (locale.trim().length && locale !== "/") ? locale : 'en' ,
    fallbackLocale: 'en',
    messages: {
      en: en,
      es: es,
      so: so
    },
  })
