<template>
  <v-app class="bg-gray-100">
    <Header />
    <div>
      <v-progress-linear v-if="$store.getters.isLoading" indeterminate color="green darken-2"></v-progress-linear>
      <v-progress-linear v-else value="100" color="green darken-2"></v-progress-linear>

      <div class="container mx-auto max-w-9xl mb-64 mt-16">
        <div v-if="step > 1" class="max-w-sm mx-auto mt-6 mb-4">
          <p class="mb-4 text-sm font-bold text-center">
            {{ $t("interface.step", { step: step, totalSteps: totalSteps }) }}
          </p>
          <v-progress-linear class="rounded" :value="progress" color="green darken-1" height="20">
            <strong>{{ progress }}%</strong>
          </v-progress-linear>
        </div>

        <LanguageSelector />

        <!--
          Put a key with the path on the stepper. When the path changes, all sub components are re-evaluated.
          Prior to the key being here, the path would change, but not all sub components would update, and
          you'd get blank steps whenever the path switched.
        -->
        <v-stepper :key="path" v-model="step" class="bg-gray-100 shadow-none mt-12">
          <v-stepper-items>
            <!-- ADDRESS -->
            <v-stepper-content :key="'address' + stepNumber('address')" :step="stepNumber('address')">
              <Address v-on:next="next" />
            </v-stepper-content>
            <!-- CONTINUE -->
            <v-stepper-content :key="'continue' + stepNumber('continue')" :step="stepNumber('continue')">
              <Continue v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- TYPE -->
            <v-stepper-content :key="'type' + stepNumber('type')" :step="stepNumber('type')">
              <OrderType v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- ORDER -->
            <v-stepper-content :key="'order' + stepNumber('order')" :step="stepNumber('order')">
              <WeeklyOrder v-if="$store.getters.isWeeklyOrder" v-on:next="next" v-on:previous="previous" />
              <DailyOrder v-if="$store.getters.isDailyOrder" v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- ORDER REVIEW -->
            <v-stepper-content :key="'orderReview' + stepNumber('orderReview')" :step="stepNumber('orderReview')">
              <OrderReview v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- PAYMENT TYPE -->
            <v-stepper-content :key="'payment-type' + stepNumber('paymentType')" :step="stepNumber('paymentType')">
              <PaymentType v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Weekly: Select Drink -->
            <v-stepper-content
              :key="'weeklySelectDrink' + stepNumber('weeklySelectDrink')"
              :step="stepNumber('weeklySelectDrink')"
            >
              <WeeklySelectDrink v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Weekly: Recurring Options -->
            <v-stepper-content
              :key="'weeklyRecurringOptions' + stepNumber('weeklyRecurringOptions')"
              :step="stepNumber('weeklyRecurringOptions')"
            >
              <WeeklyRecurOptions v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Submitter info -->
            <v-stepper-content :key="'submitterInfo' + stepNumber('submitterInfo')" :step="stepNumber('submitterInfo')">
              <Submitter v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Waiver info -->
            <v-stepper-content :key="'waiverInfo' + stepNumber('waiverInfo')" :step="stepNumber('waiverInfo')">
              <Waiver v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Payment Method -->
            <v-stepper-content :key="'paymentMethod' + stepNumber('paymentMethod')" :step="stepNumber('paymentMethod')">
              <PaymentMethod v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Personal Information -->
            <v-stepper-content :key="'personalInfo' + stepNumber('personalInfo')" :step="stepNumber('personalInfo')">
              <Information v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Address Information -->
            <v-stepper-content
              :key="'addressInformation' + stepNumber('addressInformation')"
              :step="stepNumber('addressInformation')"
            >
              <Addresses v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Contact Information -->
            <v-stepper-content
              :key="'contactInformation' + stepNumber('contactInformation')"
              :step="stepNumber('contactInformation')"
            >
              <Contact v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Household Information -->
            <v-stepper-content
              :key="'householdInformation' + stepNumber('householdInformation')"
              :step="stepNumber('householdInformation')"
            >
              <Household v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Emergency Contacts -->
            <v-stepper-content
              :key="'emergencyContacts' + stepNumber('emergencyContacts')"
              :step="stepNumber('emergencyContacts')"
            >
              <Emergency v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Doctors -->
            <v-stepper-content :key="'doctors' + stepNumber('doctors')" :step="stepNumber('doctors')">
              <Doctors v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Delivery -->
            <v-stepper-content :key="'delivery' + stepNumber('delivery')" :step="stepNumber('delivery')">
              <Delivery v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Review -->
            <v-stepper-content :key="'review' + stepNumber('review')" :step="stepNumber('review')">
              <Review v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Referrer -->
            <v-stepper-content :key="'referrer' + stepNumber('referrer')" :step="stepNumber('referrer')">
              <Referrer v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Payment -->
            <v-stepper-content :key="'payment' + stepNumber('payment')" :step="stepNumber('payment')">
              <Payment v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
            <!-- Success -->
            <v-stepper-content :key="'success' + stepNumber('success')" :step="stepNumber('success')">
              <Success v-on:next="next" v-on:previous="previous" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
    <span data-iframe-height></span>
    <Footer />
  </v-app>
</template>

<script>
import LanguageSelector from "./components/steps/support/LanguageSelector";
import Continue from "./components/steps/support/Continue";
import Address from "./components/steps/Address";
import PaymentType from "./components/steps/personal/PaymentType";
import OrderType from "./components/steps/order/OrderType";
import WeeklyOrder from "./components/steps/order/WeeklyOrder";
import WeeklySelectDrink from "./components/steps/order/weekly/WeeklySelectDrink";
import WeeklyRecurOptions from "./components/steps/order/weekly/WeeklyRecurOptions";
import DailyOrder from "./components/steps/order/DailyOrder";
import OrderReview from "./components/steps/order/OrderReview";
import Submitter from "./components/steps/personal/Submitter";
import Waiver from "./components/steps/personal/Waiver";
import PaymentMethod from "./components/steps/personal/PaymentMethod";
import Information from "./components/steps/personal/Information";
import Addresses from "./components/steps/personal/Addresses";
import Contact from "./components/steps/personal/Contact";
import Household from "./components/steps/personal/Household";
import Emergency from "./components/steps/contacts/Emergency";
import Doctors from "./components/steps/contacts/Doctors";
import Delivery from "./components/steps/submit/Delivery";
import Review from "./components/steps/submit/Review";
import Referrer from "./components/steps/submit/Referrer";
import Payment from "./components/steps/submit/Payment";
import Success from "./components/steps/submit/Success";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

export default {
  name: "App",

  components: {
    LanguageSelector,
    Address,
    Continue,
    PaymentType,
    OrderType,
    DailyOrder,
    OrderReview,
    WeeklyOrder,
    WeeklySelectDrink,
    WeeklyRecurOptions,
    Submitter,
    Waiver,
    PaymentMethod,
    Information,
    Addresses,
    Contact,
    Household,
    Emergency,
    Doctors,
    Delivery,
    Review,
    Payment,
    Referrer,
    Success,
    Header,
    Footer
  },

  data: () => ({
    paths: {
      default: {
        total: 21, // Assume longest path
        steps: {
          address: 1,
          continue: 2,
          type: 3
        }
      },
      daily: {
        total: 18, // Assume longest path
        steps: {
          address: 1,
          continue: 2,
          type: 3,
          order: 4,
          orderReview: 5,
          paymentType: 6,
          submitterInfo: 7,
          paymentMethod: 8
        }
      },
      dailyCreditCardPayment: {
        total: 16,
        steps: {
          address: 1,
          continue: 2,
          type: 3,
          order: 4,
          orderReview: 5,
          paymentType: 6,
          submitterInfo: 7,
          paymentMethod: 8,
          personalInfo: 9,
          addressInformation: 10,
          contactInformation: 11,
          delivery: 12,
          review: 13,
          referrer: 14,
          payment: 15,
          success: 16
        }
      },
      dailyOtherPayment: {
        total: 19,
        steps: {
          address: 1,
          continue: 2,
          type: 3,
          order: 4,
          orderReview: 5,
          paymentType: 6,
          submitterInfo: 7,
          waiverInfo: 8,
          personalInfo: 9,
          addressInformation: 10,
          contactInformation: 11,
          householdInformation: 12,
          emergencyContacts: 13,
          doctors: 14,
          delivery: 15,
          review: 16,
          referrer: 17,
          payment: 18,
          success: 19
        }
      },
      weekly: {
        total: 20, // Assume longest path
        steps: {
          address: 1,
          continue: 2,
          type: 3,
          order: 4,
          orderReview: 5,
          paymentType: 6,
          weeklySelectDrink: 7,
          weeklyRecurringOptions: 8,
          submitterInfo: 9,
          paymentMethod: 10
        }
      },
      weeklyCreditCardPayment: {
        total: 18,
        steps: {
          address: 1,
          continue: 2,
          type: 3,
          order: 4,
          orderReview: 5,
          paymentType: 6,
          weeklySelectDrink: 7,
          weeklyRecurringOptions: 8,
          submitterInfo: 9,
          paymentMethod: 10,
          personalInfo: 11,
          addressInformation: 12,
          contactInformation: 13,
          delivery: 14,
          review: 15,
          referrer: 16,
          payment: 17,
          success: 18
        }
      },
      weeklyOtherPayment: {
        total: 21,
        steps: {
          address: 1,
          continue: 2,
          type: 3,
          order: 4,
          orderReview: 5,
          paymentType: 6,
          weeklySelectDrink: 7,
          weeklyRecurringOptions: 8,
          submitterInfo: 9,
          waiverInfo: 10,
          personalInfo: 11,
          addressInformation: 12,
          contactInformation: 13,
          householdInformation: 14,
          emergencyContacts: 15,
          doctors: 16,
          delivery: 17,
          review: 18,
          referrer: 19,
          payment: 20,
          success: 21
        }
      }
    },
    appVersion: {
      allina: false
    }
  }),

  created: function() {
    this.$store.commit("generateSubmissionId");
  },

  methods: {
    next: function() {
      this.step++;
      this.scrollTop();
    },
    previous: function() {
      this.step--;
    },
    stepNumber: function(step) {
      return this.paths[this.path].steps[step] ?? -1;
    },
    scrollTop: function() {
      window.location.href="#app";
    }
  },

  computed: {
    progress: function() {
      return Math.ceil((this.step / this.totalSteps) * 100);
    },
    path: function() {
      if (this.$store.getters.isDailyOrder && this.$store.getters.isPrivatePaymentType) {
        return "dailyCreditCardPayment";
      }
      if (this.$store.getters.isDailyOrder && this.$store.getters.isWaiverPaymentType) {
        return "dailyOtherPayment";
      }
      if (this.$store.getters.isDailyOrder) {
        return "daily";
      }
      if (this.$store.getters.isWeeklyOrder && this.$store.getters.isPrivatePaymentType) {
        return "weeklyCreditCardPayment";
      }
      if (this.$store.getters.isWeeklyOrder && this.$store.getters.isWaiverPaymentType) {
        return "weeklyOtherPayment";
      }
      if (this.$store.getters.isWeeklyOrder) {
        return "weekly";
      }
      return "default";
    },
    totalSteps: function() {
      return this.paths[this.path].total;
    },
    // Shim step, component was originally built with this state being local.
    step: {
      get: function () {
        return this.$store.state.step;
      },
      set: function (step) {
        this.$store.commit('setStep', step);
      }
    }
  }
};
</script>
