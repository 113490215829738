<template>
  <div class="max-w-2xl mx-auto">
    <h1>{{ $t(`addresses.${$store.getters.subject}.title`) }}</h1>
    <v-form v-model="valid" class="mt-6 mb-2">
      <div class="flex justify-between -mx-2">
        <v-text-field
          class="px-2"
          :label="$t('interface.streetAddress')"
          :value="$store.state.entry.addresses.mailing.street"
          required
          disabled
          outlined
        ></v-text-field>
      </div>
      <div class="flex justify-between -mx-2">
        <v-text-field
          class="px-2"
          :label="$t('interface.city')"
          :value="$store.state.entry.addresses.mailing.city"
          required
          disabled
          outlined
        ></v-text-field>
        <v-text-field
          class="px-2"
          :label="$t('interface.state')"
          :value="$store.state.entry.addresses.mailing.state"
          required
          disabled
          outlined
        ></v-text-field>
        <v-text-field
          class="px-2"
          :label="$t('interface.postal')"
          :value="$store.state.entry.addresses.mailing.postal"
          required
          disabled
          outlined
        ></v-text-field>
      </div>

      <!-- Toggle Billing -->
      <v-checkbox v-if="!appVersion.allina" v-model="billingSameAsDelivery" :label="$t('addresses.billingIsSameAsDelivery')"></v-checkbox>

      <!-- Billing Address -->
      <div v-if="billingIsRequired">
        <h1 class="mb-2">{{ $t(`addresses.${$store.getters.subject}.billing`) }}</h1>

        <div class="flex justify-between -mx-2">
          <v-text-field
            class="px-2"
            v-model.trim="billing.street"
            :rules="rules.billing.street"
            :label="$t('interface.streetAddress')"
            outlined
          ></v-text-field>
        </div>
        <div class="flex justify-between -mx-2">
          <v-text-field
            class="px-2"
            v-model.trim="billing.city"
            :rules="rules.billing.city"
            :label="$t('interface.city')"
            outlined
          ></v-text-field>
          <v-text-field
            class="px-2"
            v-model.trim="billing.state"
            :rules="rules.billing.state"
            :label="$t('interface.state')"
            outlined
          ></v-text-field>
          <v-text-field
            class="px-2"
            v-model.trim="billing.postal"
            :rules="rules.billing.postal"
            :label="$t('interface.postal')"
            outlined
          ></v-text-field>
        </div>
      </div>

      <p><small>{{ $t('interface.delivery_instructions_label') }}</small></p>
      <div class="flex justify-between -mx-2">
          <v-text-field
            class="px-2"
            v-model.trim="billing.deliveryInstructions"
            :label="$t('interface.delivery_instructions')"
            outlined
          ></v-text-field>
        </div>
    </v-form>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :nextDisabled="!valid" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Addresses",
  components: {},
  data: () => ({
    valid: false,
    billingSameAsDelivery: true,
    billing: {
      street: "",
      city: "",
      state: "",
      postal: "",
      deliveryInstructions: ""
    },
    appVersion: {
      allina: false
    }
  }),
  mounted: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  computed: {
    billingIsRequired: function() {
      return !this.billingSameAsDelivery;
    },
    rules: function() {
      let rules = {
        billing: {
          street: [],
          city: [],
          state: [],
          postal: []
        }
      };
      if (this.billingIsRequired) {
        rules.billing.street = [v => !!v || "A street is required"];
        rules.billing.city = [v => !!v || "A city is required"];
        rules.billing.state = [v => !!v || "A state is required"];
        rules.billing.postal = [v => !!v || "A postal code is required"];
      }
      return rules;
    }
  },
  methods: {
    next: function() {
      if (this.billingIsRequired) {
        this.$store.commit("setBillingAddress", this.billing);
      } else {
        this.$store.commit("clearBillingAddress");
      }
      this.$store.commit("setDeliveryInstructions", this.billing.deliveryInstructions);
      this.$emit("scrollTop");
      this.$emit("next");
    }
  }
};
</script>

<style></style>
