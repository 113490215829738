<template>
  <div class="max-w-2xl mx-auto">
    <h1 class="mb-4">{{ $t("waiver.title") }}</h1>
    <v-form v-model="valid">
      <v-select
        v-model="form.payer"
        :items="[
          'MN Medicaid',
          'Blue Plus (Bridgeview)',
          'Health Partners',
          'Medica',
          'UCare',
        ]"
        :rules="rules.required"
        required
        :label="$t('waiver.form.payer')"
        outlined
      ></v-select>

      <v-select
        v-model="form.waiver"
        :items="[
          'Alternative Care Program',
          'Brain Injury (BI)',
          'Community Access for Disability',
          'Inclusion (CADI)',
          'Development Disabilities Waiver (DD)',
          'Elderly Waiver (EW)',
          'Essential Community Supports Program (ECS)',
        ]"
        required
        :rules="rules.required"
        :label="$t('waiver.form.waiver')"
        outlined
      ></v-select>

      <!-- OLD HINT: hint="(Does not apply to COVID-19 relief.)" -->
      <v-text-field
        :label="$t('waiver.form.medicaidPmi')"
        v-model.trim="form.pmi"
        outlined
        class="mb-4"
        persistent-hint
      ></v-text-field>

      <!-- hint="(Bridgeview, Health Partners, Medica, UCare. Does not apply to COVID-19 relief.)" -->
      <v-text-field
        :label="$t('waiver.form.payerId')"
        v-model.trim="form.payerId"
        outlined
        class="mb-4"
        hint="(Bridgeview, Health Partners, Medica, UCare.)"
        persistent-hint
      ></v-text-field>

      <!-- This is HIDDEN at client request - may come back at a later date  -->
      <!-- I am enrolling a new client through a waivered service and would like information about
      additional COVID-19 relief meals beyond those authorized by the waiver. Up to 21 meals a week may be available. -->
      <!--<p class="v-label theme&#45;&#45;light -mb-2 leading-normal mt-2 hidden">
        {{ $t("waiver.form.covidReliefInformation") }}
      </p>-->
      <v-radio-group v-model="form.informationAboutAdditionalCovidRelief" class="hidden">
        <v-radio :label="$t('interface.yes')" value="Yes"></v-radio>
        <v-radio :label="$t('interface.no')" value="No"></v-radio>
      </v-radio-group>
      <!-- end hidden -->

      <p class="v-label theme--light -mb-2 leading-normal mt-2">
        {{ $t("waiver.form.spenddownOrWaiver") }}
      </p>
      <v-radio-group v-model="form.spenddownOrWaiverObligation">
        <v-radio :label="$t('interface.yes')" value="Yes"></v-radio>
        <v-radio :label="$t('interface.no')" value="No"></v-radio>
      </v-radio-group>

      <v-text-field
        v-if="form.spenddownOrWaiverObligation === 'Yes'"
        :label="$t('waiver.form.spenddownOrWaiverAmount')"
        v-model="form.spenddownOrWaiverObligationAmount"
        outlined
        prefix="$"
        type="number"
        class="mt-4"
      ></v-text-field>

      <p class="v-label theme--light -mb-2 leading-normal mt-2">
        {{ $t("waiver.form.serviceAgreementAuthRequested") }}
      </p>
      <v-radio-group :rules="rules.required" required v-model="form.serviceAgreementAuthorizationRequested">
        <v-radio :label="$t('interface.yes')" value="Yes"></v-radio>
        <v-radio :label="$t('interface.no')" value="No"></v-radio>
      </v-radio-group>

      <v-select
        class="mt-2"
        :rules="rules.required"
        required
        v-model="form.numberOfMealsAuthorized"
        :items="[1, 2, 3, 4, 5, 6, 7]"
        :label="$t('waiver.form.numberAuthToReceive')"
        outlined
      ></v-select>

      <v-textarea :label="$t('waiver.form.comments')" outlined v-model.trim="form.comments"></v-textarea>
    </v-form>
    <v-footer color="primary lighten-1" app padless>
        <StepNavigation :nextDisabled="!valid" v-on:next="next" v-on:previous="previous" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Waiver",
  components: {},
  data: () => ({
    valid: false,
    form: {
      payer: "",
      waiver: "",
      pmi: "",
      payerId: "",
      informationAboutAdditionalCovidRelief: "",
      spenddownOrWaiverObligation: "",
      spenddownOrWaiverObligationAmount: "",
      serviceAgreementAuthorizationRequested: "",
      numberOfMealsAuthorized: "",
      comments: ""
    },
    rules: {
      required: [v => !!v || "This field is required"]
    }
  }),
  computed: {},
  methods: {
    next: function() {
      this.$store.commit("setWaiverInformation", this.form);
      this.$emit("scrollTop");
      this.$emit("next");
    },
    previous: function() {
      this.$emit('previous');
    }
  }
};
</script>

<style></style>
