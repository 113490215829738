var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-3xl mx-auto"},[(!_vm.appVersion.allina)?_c('h1',[_vm._v(_vm._s(_vm.$t("weeklyRecur.title")))]):_vm._e(),(_vm.appVersion.allina)?_c('h1',[_vm._v("Do you have any dietary restrictions?")]):_vm._e(),(!_vm.appVersion.allina)?_c('p',{staticClass:"mt-2 text-gray-800"},[_vm._v(_vm._s(_vm.$t("weeklyRecur.description")))]):_vm._e(),_c('v-form',{staticClass:"mt-6 mb-2",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(!_vm.appVersion.allina)?_c('v-radio-group',{attrs:{"rules":_vm.rules.required},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('v-radio',{attrs:{"label":_vm.$t('weeklyRecur.form.type.choice'),"value":"Recurring, same meals"}}),_c('v-radio',{attrs:{"label":_vm.$t('weeklyRecur.form.type.dietary'),"value":"Recurring, chef's choice"}}),_c('v-radio',{attrs:{"label":_vm.$t('weeklyRecur.form.type.none'),"value":"One-time"}})],1):_vm._e(),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("weeklyRecur.form.dietaryNote"))+" ")]),_c('v-select',{staticClass:"mt-4",attrs:{"items":[
        'Vegetarian',
        'Gluten Free',
        'Lactose Free',
        'Renal',
        'Diabetic Friendly',
        'Low Sodium',
        'Mechanical Soft',
        'Pureed',
        'Halal',
        'Kosher',
        'Traditional Hmong',
        'Latino-focused (Mexican/Latin American flavors)',
        'Other',
        'None'
      ],"rules":_vm.rules.required,"multiple":"","label":_vm.$t('weeklyRecur.form.dietaryPreferences'),"outlined":""},model:{value:(_vm.form.dietaryPreferences),callback:function ($$v) {_vm.$set(_vm.form, "dietaryPreferences", $$v)},expression:"form.dietaryPreferences"}}),(_vm.form.dietaryPreferences == 'Other')?_c('v-text-field',{attrs:{"label":"Other Dietary Preference","outlined":""},model:{value:(_vm.form.otherDietaryPreference),callback:function ($$v) {_vm.$set(_vm.form, "otherDietaryPreference", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.otherDietaryPreference"}}):_vm._e(),(_vm.form.type === 'choice')?_c('p',{staticClass:"text-red-error font-bold mt-4"},[_vm._v(" "+_vm._s(_vm.$t("weeklyRecur.renewalNotice"))+" ")]):_vm._e()],1),_c('v-footer',{attrs:{"color":"primary lighten-1","app":"","padless":""}},[_c('StepNavigation',{attrs:{"nextDisabled":!_vm.valid},on:{"next":function($event){return _vm.next()},"previous":function($event){return _vm.$emit('previous')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }