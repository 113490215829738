<template>
  <div class="max-w-4xl mx-auto">
    <div>
      <v-alert v-if="!appVersion.allina" color="#F5AA01" type="info">
        <PaymentOptionHelp type="link">
          {{ $t("paymentHelp.button") }}
        </PaymentOptionHelp>
      </v-alert>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="bg-white rounded border-solid border-gray-500 border shadow-md p-8">
        <h1 class="mb-2">{{ $t(`orderType.daily.title`) }}</h1>
        <ul>
          <li>{{ $t(`orderType.daily.bullets.0`) }}</li>
          <li v-if="!appVersion.allina">{{ $t(`orderType.daily.bullets.1`) }}</li>
          <li>{{ $t(`orderType.daily.bullets.2`) }}</li>
          <li v-if="!appVersion.allina">{{ $t(`orderType.daily.bullets.3`) }}</li>
        </ul>
        <v-btn class="mt-4" color="primary" @click="next('daily')">{{ $t("interface.choose") }}</v-btn>
      </div>
      <div class="bg-white rounded border-solid border-gray-500 border shadow-md p-8">
        <h1 class="mb-2">{{ $t(`orderType.weekly.title`) }}</h1>
        <ul>
          <li>{{ $t(`orderType.weekly.bullets.0`) }}</li>
          <li v-if="!appVersion.allina">{{ $t(`orderType.weekly.bullets.1`) }}</li>
          <li v-if="!appVersion.allina">{{ $t(`orderType.weekly.bullets.2`) }}</li>
          <li v-if="!appVersion.allina">{{ $t(`orderType.weekly.bullets.3`) }}</li>
          <li v-if="appVersion.allina">{{ $t(`orderType.weekly.bullets_allina.0`) }}</li>
        </ul>
        <v-btn class="mt-4" color="primary" @click="next('weekly')">{{ $t("interface.choose") }}</v-btn>
      </div>
    </div>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation :showNext="false" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
import PaymentOptionHelp from "./../support/PaymentOptionHelp";
export default {
  name: "OrderType",
  components: {
    PaymentOptionHelp,
  },
  data: () => ({
    type: null,
    appVersion: {
      allina: false
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  methods: {
    next: function(type) {
      this.$store.commit("setType", type);
      this.$emit('scrollTop');
      this.$emit("next");
    }
  }
};
</script>

<style></style>
