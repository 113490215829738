<template>
  <div class="max-w-2xl mx-auto">
    <h1>{{ $t(`contact.${$store.getters.subject}.title`) }}</h1>
    <v-form v-model="valid" class="mt-6 mb-2">
      <v-text-field
        :label="$t('interface.phone')"
        v-mask="'###-###-####'"
        v-model="contactInformation.phone"
        :rules="rules.phone"
        placeholder="555-555-5555"
        outlined
      ></v-text-field>
      <v-text-field
        :label="$t('interface.email')"
        v-model.trim="contactInformation.email"
        :rules="$store.getters.isWaiverPaymentType || !$store.getters.isOrderingForThemselves ? [true] : rules.email"
        outlined
      ></v-text-field>

      <v-select
          :items="[
            'Yes',
            'No',
          ]"
          v-model="contactInformation.medicalWaiver"
          :label="'Does the patient have a pending medical waiver?'"
          outlined
          v-if="appVersion.allina"
        ></v-select>

    </v-form>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation v-on:next="next" :nextDisabled="!valid" v-on:previous="$emit('previous')" />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Contact",
  components: {},
  data: () => ({
    valid: false,
    contactInformation: {
      phone: "",
      email: "",
      medicalWaiver: "No"
    },
    appVersion: {
      allina: false
    },
    rules: {
      required: [v => !!v || "This field is required"],
      email: [
        v => {
          return (
            v.search(
              // eslint-disable-next-line no-useless-escape
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) > -1 || "Please enter a valid email"
          );
        }
      ],
      phone: [
        v => {
          return (
            v.search(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/) > -1 || "Please enter a valid phone number"
          );
        }
      ]
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  methods: {
    next: function() {
      this.$store.commit("setContactInformation", this.contactInformation);
      this.$emit("scrollTop");
      this.$emit("next");
    }
  }
};
</script>

<style></style>
