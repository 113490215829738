<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      max-height="800px"
      class="pt-2"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="secondary"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('upcomingMeals.prompt')}}
        </v-btn>
      </template>

      <v-card>
        <v-toolbar
            flat
            dense
        >
          <div class="flex-grow-1"></div>
          <v-btn
              icon
              small
              @click="dialog = false"
              color="text-gray-400"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
          <v-card-text>
              <UpcomingDailyMeals/>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UpcomingDailyMeals from "./UpcomingDailyMeals";

export default {
  name: "UpcomingDailyMealsDialog",
  components: {
      UpcomingDailyMeals
  },
  data: () => ({
      dialog: false
  }),
  methods: {
  },
};
</script>

<style></style>
