<template>
  <div class="max-w-2xl mx-auto" v-if="!appVersion.allina">
    <div v-if="$store.state.entry.paymentMethod === 'creditCard'">
      <h1>
        {{ $t(`cardPayment.${$store.getters.subject}.title`) }}
      </h1>

      <p class="mt-2">
        {{ $t(`cardPayment.${$store.getters.subject}.info`) }}
        <strong> {{ formatNumberToUsd($store.getters.getPurchasePrice) }}. </strong>
      </p>

      <div v-show="$store.getters.isStripeLoading || $store.getters.isLoading" class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-show="!$store.getters.isStripeLoading">
        <form id="payment-form" class="mt-6 p-4 rounded shadow-lg border border-solid border-gray-500 bg-white">
          <div id="card-element"><!--Stripe.js injects the Card Element--></div>
        </form>

        <p v-if="$store.state.stripe.cardState.error" class="text-red-error font-bold mt-2">
          {{ $store.state.stripe.cardState.error }}
        </p>

        <p v-if="$store.getters.isOrderRepeating" class="mt-6 font-italic">
          {{ $t(`cardPayment.${$store.getters.subject}.next`) }}
        </p>

        <div
          v-if="payment.error && $store.state.stripe.cardState.error != payment.error"
          class="text-red-error font-bold mt-2"
        >
          {{ payment.message }}
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="$store.getters.isWaiverPaymentType">
        <h1>
          {{ $t(`otherPayment.waiver.${$store.getters.subject}.title`) }}
        </h1>
        <p>
          {{ $t(`otherPayment.waiver.${$store.getters.subject}.next`) }}
        </p>
      </div>
      <div v-else>
        <h1>
          {{ $t(`otherPayment.private.${$store.getters.subject}.title`) }}
        </h1>
        <p>
          {{ $t(`otherPayment.private.${$store.getters.subject}.next`) }}
        </p>
      </div>
    </div>
    <v-footer color="primary lighten-1" app padless>
      <StepNavigation
        :nextDisabled="preventNext"
        :showLoading="true"
        v-on:next="next"
        v-on:previous="$emit('previous')"
      />
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Payment",
  data: () => ({
    payment: {
      error: false,
      message: ""
    },
    appVersion: {
      allina: false
    }
  }),
  created: function() {
    let uri = window.location.search;
    let params = new URLSearchParams(uri);
    this.appVersion.allina = !!(params.get("app_version") && params.get("app_version") === "allina");
  },
  methods: {
    next: async function() {
      this.$store.commit("setLoading", true);
      if (this.$store.getters.isCreditCardPayment) {
        const payment = await this.$store.dispatch("completeStripePayment");
        if (payment.error) {
          this.payment.error = true;
          this.payment.message = payment.error.message;
          this.$store.commit("setLoading", false);
          return;
        }
        this.$store.commit("setSuccessfulPaymentIntentId", payment.paymentIntent.id);
      }
      this.$store.dispatch("submit").then(() => {
        this.$store.commit("setLoading", false);
        this.$emit("scrollTop");
        this.$emit("next");
      });
    },
    formatNumberToUsd(number) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(number);
    }
  },
  computed: {
    preventNext() {
      return !!((this.$store.getters.isLoading ||
          this.$store.state.stripe.cardState.disabled ||
          this.$store.state.stripe.cardState.error) &&
        this.$store.state.entry.paymentMethod === "creditCard");

    }
  }
};
</script>

<style></style>
