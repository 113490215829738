<template>
  <div>
    <table class="w-full">
      <thead>
        <tr>
          <th class="w-auto text-left">{{ $t('interface.meal') }}</th>
          <th class="w-16 text-center">{{ $t('interface.qty') }}</th>
          <th class="w-8"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="group in $store.state.entry.order.weekly.groups">
          <tr v-for="ordered in group.meals" :key="ordered.meal.id">
            <td class="py-2 pr-1">{{ ordered.meal.name }}<span v-if="displayHalal(ordered.meal)" class="font-bold text-xs text-red-error"> ${{ $store.getters.getPerMealPriceHalal }}/meal</span></td>
            <td class="text-center py-1">
              <v-text-field  class="mb-0" :hide-details="true" label="" type="number" dense outlined min="1" :value="ordered.quantity" v-on:change="onChange(group, ordered, $event)"></v-text-field>
            </td>

            <td>
              <v-btn icon @click="$store.commit('removeOrderedMeal', { menu: group.menu, meal: ordered })">
                <v-icon color="secondary">mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    name: "WeeklyOrderedItems",
    props: [""],
    components: {},
    data: () => ({}),
    methods: {
        onChange(group, ordered, quantity) {
            this.$store.commit('updateOrderedMealQuantity', {
                menu: group.menu,
                meal: ordered,
                quantity: Math.abs(quantity)
            });
        },
        displayHalal(meal) {
            return meal.categories.find( cat => cat.value === 'halal' ) && this.$store.getters.getPerMealPrice !== this.$store.getters.getPerMealPriceHalal;
        }
    },
    computed: {}
};
</script>

<style></style>
