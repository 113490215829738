var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-xl mx-auto"},[_c('h1',[_vm._v(_vm._s(_vm.$t("weeklyDrink.title")))]),(!_vm.appVersion.allina)?_c('v-radio-group',{attrs:{"rules":_vm.rules.required},model:{value:(_vm.beverage),callback:function ($$v) {_vm.beverage=$$v},expression:"beverage"}},_vm._l(([
        'onePercentMilk',
        'skimMilk',
        'appleJuice',
        'orangeJuice',
        'grapeJuice',
        'noBeverage',
        'varietyBeverage',
      ]),function(beverage){return _c('v-radio',{key:beverage,attrs:{"label":_vm.$t(`interface.drinkOptions.${beverage}`),"value":beverage}})}),1):_vm._e(),(_vm.appVersion.allina)?_c('v-radio-group',{attrs:{"rules":_vm.rules.required},model:{value:(_vm.beverage),callback:function ($$v) {_vm.beverage=$$v},expression:"beverage"}},_vm._l((['onePercentMilk', 'skimMilk', 'juice', 'noBeverage']),function(beverage){return _c('v-radio',{key:beverage,attrs:{"label":_vm.$t(`interface.drinkOptions.${beverage}`),"value":beverage}})}),1):_vm._e(),_c('v-footer',{attrs:{"color":"primary lighten-1","app":"","padless":""}},[_c('StepNavigation',{attrs:{"nextDisabled":!_vm.beverage},on:{"next":function($event){return _vm.next()},"previous":_vm.previous}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }