<template>
    <footer class="main-footer" style="background-image:url('/images/heartpattern.png')">
        <div class="site-width">
            <div class="flex flex-wrap">
                <div class="left-col">
                    <h3 class="lilita weight-bold">Contact:</h3><br />
                    <p>Phone: <a href="tel:6126233363">(612) 623-3363</a> <br>
                        Fax: (612) 331-9401<br>
                        Email: <a href="mailto:info@meals-on-wheels.com" title="Email Metro Meals on Wheels">info@meals-on-wheels.com</a>
                    </p>
                    <p>1200 Washington Avenue S.,<br> Suite 380, Minneapolis, MN 55415</p>
                </div>
                <div class="right-col">
                    <h3 class="lilita weight-bold">Connect:</h3>
                    <ul class="social-menu">
                        <li class="social-menu_item" style="width: 50px;"><a class="social-menu_item_link" target="_blank" href="https://www.facebook.com/MetroMealsOnWheels?ref=profile" title="Metro Meals on Wheels on Facebook"><svg id="Capa_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m512 256c0-141.4-114.6-256-256-256s-256 114.6-256 256 114.6 256 256 256c1.5 0 3 0 4.5-.1v-199.2h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5c107-30.7 185.3-129.2 185.3-246.1z"></path></svg></a></li>
                        <li class="social-menu_item" style="width: 58px;"><a class="social-menu_item_link" target="_blank" href="https://twitter.com/Meals_on_Wheels" title="Metro Meals on Wheels on X"><svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512"><path fill="#fff" d="M256 0c141.384 0 256 114.616 256 256 0 141.384-114.616 256-256 256C114.616 512 0 397.384 0 256 0 114.616 114.616 0 256 0zm62.64 157.549h33.401l-72.974 83.407 85.85 113.495h-67.222l-52.645-68.837-60.244 68.837h-33.422l78.051-89.212-82.352-107.69h68.924l47.59 62.916 55.043-62.916zm-11.724 176.908h18.509L205.95 176.494h-19.861l120.827 157.963z"/></svg></a></li>
                        <li class="social-menu_item" style="width: 58px;"><a class="social-menu_item_link" target="_blank" href="https://www.instagram.com/mealsonwheelsmn/" title="Metro Meals on Wheels on Instagram"><svg data-name="Instagram w/circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 19.2"><path d="M13.498 6.651a1.656 1.656 0 0 0-.95-.949 2.766 2.766 0 0 0-.928-.172c-.527-.024-.685-.03-2.02-.03s-1.493.006-2.02.03a2.766 2.766 0 0 0-.929.172 1.656 1.656 0 0 0-.949.95 2.766 2.766 0 0 0-.172.928c-.024.527-.03.685-.03 2.02s.006 1.493.03 2.02a2.766 2.766 0 0 0 .172.929 1.656 1.656 0 0 0 .95.949 2.766 2.766 0 0 0 .928.172c.527.024.685.029 2.02.029s1.493-.005 2.02-.03a2.766 2.766 0 0 0 .929-.171 1.656 1.656 0 0 0 .949-.95 2.766 2.766 0 0 0 .172-.928c.024-.527.029-.685.029-2.02s-.005-1.493-.03-2.02a2.766 2.766 0 0 0-.171-.929zM9.6 12.168A2.568 2.568 0 1 1 12.168 9.6 2.568 2.568 0 0 1 9.6 12.168zm2.669-4.637a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6zM11.267 9.6A1.667 1.667 0 1 1 9.6 7.933 1.667 1.667 0 0 1 11.267 9.6zM9.6 0a9.6 9.6 0 1 0 9.6 9.6A9.6 9.6 0 0 0 9.6 0zm4.97 11.661a3.67 3.67 0 0 1-.233 1.214 2.556 2.556 0 0 1-1.462 1.462 3.67 3.67 0 0 1-1.213.233c-.534.024-.704.03-2.062.03s-1.528-.006-2.062-.03a3.67 3.67 0 0 1-1.213-.233 2.556 2.556 0 0 1-1.462-1.462 3.67 3.67 0 0 1-.233-1.213c-.024-.534-.03-.704-.03-2.062s.006-1.528.03-2.062a3.67 3.67 0 0 1 .232-1.213 2.556 2.556 0 0 1 1.463-1.463 3.67 3.67 0 0 1 1.213-.232c.534-.024.704-.03 2.062-.03s1.528.006 2.062.03a3.67 3.67 0 0 1 1.213.232 2.556 2.556 0 0 1 1.462 1.463 3.67 3.67 0 0 1 .233 1.213c.024.534.03.704.03 2.062s-.006 1.528-.03 2.062z"/></svg></a></li>
                        <li class="social-menu_item" style="width: 58px;"><a class="social-menu_item_link" target="_blank" href="https://www.youtube.com/user/MetroMealsonWheels" title="Metrol Meals on Wheels on YouTube"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m224.113281 303.960938 83.273438-47.960938-83.273438-47.960938zm0 0"></path><path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm159.960938 256.261719s0 51.917969-6.585938 76.953125c-3.691406 13.703125-14.496094 24.507812-28.199219 28.195312-25.035156 6.589844-125.175781 6.589844-125.175781 6.589844s-99.878906 0-125.175781-6.851562c-13.703125-3.6875-24.507813-14.496094-28.199219-28.199219-6.589844-24.769531-6.589844-76.949219-6.589844-76.949219s0-51.914062 6.589844-76.949219c3.6875-13.703125 14.757812-24.773437 28.199219-28.460937 25.035156-6.589844 125.175781-6.589844 125.175781-6.589844s100.140625 0 125.175781 6.851562c13.703125 3.6875 24.507813 14.496094 28.199219 28.199219 6.851562 25.035157 6.585938 77.210938 6.585938 77.210938zm0 0"></path></svg></a></li>
                        <li class="social-menu_item" style="width: 58px;"><a class="social-menu_item_link" target="_blank" href="https://visitor.r20.constantcontact.com/d.jsp?llr=5hgut7n6&amp;p=oi&amp;m=1011351165182&amp;sit=k5glmt74&amp;f=7cf14270-53d9-4e8b-9ce6-590e0655c6f8" title="Subscribe to the Metro Meals on Wheels Newsletter"><svg id="Layer_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m256 512c-141.159 0-256-114.841-256-256s114.841-256 256-256 256 114.841 256 256-114.841 256-256 256zm132.758-166.069c3.156 0 5.725-2.568 5.725-5.725v-160.717l-130.25 103.432c-2.41 1.915-5.323 2.872-8.234 2.872s-5.823-.958-8.234-2.872l-130.249-103.432v160.717c0 3.157 2.569 5.725 5.725 5.725zm-19.96-179.862-112.798 89.575-112.799-89.575zm52.168 5.725v168.414c0 17.759-14.449 32.208-32.208 32.208h-265.517c-17.76 0-32.207-14.449-32.207-32.208v-168.414c0-17.759 14.448-32.207 32.207-32.207h265.517c17.759-.001 32.208 14.448 32.208 32.207z" fill-rule="evenodd"></path></svg>
                    </a></li></ul><a class="social-menu_item_link" target="_blank" href="https://visitor.r20.constantcontact.com/d.jsp?llr=5hgut7n6&amp;p=oi&amp;m=1011351165182&amp;sit=k5glmt74&amp;f=7cf14270-53d9-4e8b-9ce6-590e0655c6f8" title="Subscribe to the Metro Meals on Wheels Newsletter">

                    </a>
                    <p><a class="social-menu_item_link" target="_blank" href="https://visitor.r20.constantcontact.com/d.jsp?llr=5hgut7n6&amp;p=oi&amp;m=1011351165182&amp;sit=k5glmt74&amp;f=7cf14270-53d9-4e8b-9ce6-590e0655c6f8" title="Subscribe to the Metro Meals on Wheels Newsletter">
                      </a><a href="https://meals-on-wheels.com/privacy-policy/" title="Privacy Policy">Website Privacy Policy</a> | <a href="https://meals-on-wheels.com/donate/donor-privacy-and-security-policy/" title="Donor Privacy and Security Policy">Donor Privacy and Security Policy</a> | <a href="https://meals-on-wheels.com/site-map" title="Site Map">Site Map</a>
                    </p>

                    <p>&copy; Copyright {{ year }} Metro Meals on Wheels. <span class="break-mobile">All Rights Reserved.</span></p>

                    <p><small>Site by <a href="https://brandography.com">Brandography</a></small></p>

                </div>
            </div>
        </div>

        <a class="back-to-top" href="#">
            <i class="fa fa-arrow-circle-up" aria-hidden="true"></i>
        </a>
    </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data: () => ({
    'year': new Date().getFullYear(),
  }),
  created: function() {},
  methods: {}
};
</script>

<style>
/* FOOTER */
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap');
body footer.main-footer {
  background-color: #f5aa01;
  color: #666;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
}
body footer.main-footer a, body footer.main-footer a:visited, body footer.main-footer a:hover, body footer.main-footer a:active {
  color: currentColor;
  text-decoration: none;
}
body footer.main-footer h3, body footer.main-footer p {
  color: white;
}
body footer.main-footer h3 {
  font-family: 'Passion One', cursive;
  font-size: 20px;
}
body footer.main-footer .left-col {
  width: 50%;
  padding-left: 200px;
  line-height: 1.4;
  box-sizing: border-box;
  padding-right: 40px;
}
body footer.main-footer .right-col {
  width: 50%;
  box-sizing: border-box;
}

@media (max-width: 1300px) {
  body footer.main-footer .site-width {
    padding-left: 20px;
    padding-right: 20px;
 }
  body footer.main-footer .left-col {
    width: 40%;
    padding-left: 0;
 }
  body footer.main-footer .right-col {
    width: 60%;
 }
}
@media (max-width: 1024px) {
  body footer.main-footer .right-col {
    padding-left: 20px;
 }
}
@media (max-width: 767px) {
  body footer.main-footer {
    text-align: center;
 }
  body footer.main-footer .left-col {
    width: 100%;
    padding-right: 0;
 }
  body footer.main-footer .right-col {
    width: 100%;
    padding-left: 0;
 }
 .footer-meals-image {
   margin: 0 auto;
 }
}

/* END FOOTER */

/* SOCIAL MENU */
.social-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
}

.social-menu_item {
  display: inline-block;
  padding: 0 0.5em;
  width: 50px;
}
.social-menu_item a {
  display: block;
}

li.social-menu_item:first-of-type {
  padding-left: 0;
}

.social-menu svg {
  max-width: 100%;
  fill: white;
}

@media screen and (max-width: 900px) {
  .social-menu {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
  }
}

ul.social-menu {
    padding-left:0;
    margin-top:14px;
    margin-bottom: 14px;
}
/* END SOCIAL MENU */

/* SITE WIDTH */
.site-width {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1240px) {
  .site-width {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 1300px) {
  footer.main-footer .site-width {
    padding-left: 20px;
    padding-right: 20px;
  }
  footer.main-footer .left-col {
    width: 40%;
    padding-left: 0;
  }
  footer.main-footer .right-col {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  footer.main-footer .right-col {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  footer.main-footer {
    text-align: center;
  }
  footer.main-footer .left-col {
    width: 100%;
    padding-right: 0;
  }
  footer.main-footer .right-col {
    width: 100%;
    padding-left: 0;
  }
}
/* END SITE WIDTH */
</style>
